var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "vx-uiux-cta-centered-text",
      class: _vm.componentTheme,
      attrs: { id: "vx-uiux-cta-centered-text" }
    },
    [
      _vm.uiuxImageData.imageSrcD
        ? _c("img", {
            attrs: {
              src: _vm.uiuxImageData.imageSrcD,
              alt: _vm.uiuxImageData.imageSrcAltTextD
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextData.header
        ? _c("div", {
            ref: "header",
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.uiuxTextData.header) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextData.text
        ? _c("div", {
            ref: "description",
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.uiuxTextData.text) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextData.ctaText && _vm.uiuxTextData.ctaLink
        ? _c("a", { attrs: { href: _vm.uiuxTextData.ctaLink } }, [
            _c(
              "button",
              {
                ref: "button",
                staticClass: "form-blue-button",
                class: { "form-blue-button-alternative": _vm.isWhite },
                attrs: { tabindex: "-1", role: "presentation" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.uiuxTextData.ctaText) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-72d23e2e", { render: render, staticRenderFns: staticRenderFns })
  }
}