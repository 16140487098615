var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-sub-categories", class: _vm.componentTheme },
    [
      _vm.imageTextData.header
        ? _c("div", {
            ref: "header",
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.imageTextData.header) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sub-categories", attrs: { role: "list" } },
        _vm._l(_vm.imageTextData.subImageTiles, function(tile) {
          return _c(
            "div",
            { staticClass: "sub-category", attrs: { role: "listitem" } },
            [
              _c("a", { attrs: { href: tile.ctaLink } }, [
                _c("img", {
                  attrs: {
                    src: tile.imageData.imageSrcD,
                    alt: tile.imageData.imageSrcAltTextD
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _c("a", { attrs: { href: tile.ctaLink } }, [
                  _vm._v(
                    "\n              " + _vm._s(tile.ctaText) + "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: tile.hyperlinks,
                      expression: "tile.hyperlinks"
                    }
                  ],
                  staticClass: "links"
                },
                _vm._l(tile.hyperlinks, function(link) {
                  return _c(
                    "a",
                    { staticClass: "link", attrs: { href: link.ctaLink } },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(link.ctaText) +
                          "\n              "
                      )
                    ]
                  )
                })
              )
            ]
          )
        })
      ),
      _vm._v(" "),
      _vm.imageTextData.ctaLink && _vm.imageTextData.ctaText
        ? _c(
            "a",
            {
              ref: "button",
              attrs: { href: _vm.imageTextData.ctaLink, tabindex: "-1" }
            },
            [
              _c("button", { attrs: { role: "presentation" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.imageTextData.ctaText) +
                    "\n      "
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d62101a0", { render: render, staticRenderFns: staticRenderFns })
  }
}