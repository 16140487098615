var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-banner-text",
      class: [
        { "image-on-left": _vm.uiuxImageData.imageAlignment === "left" },
        _vm.componentTheme
      ]
    },
    [
      _c("lazy-component", { on: { show: _vm.onLazyLoad } }),
      _vm._v(" "),
      _c("div", { ref: "leftSide", staticClass: "left-side" }, [
        _vm.uiuxTextData.header
          ? _c("div", {
              ref: "header",
              staticClass: "header",
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.header) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.text
          ? _c("div", {
              ref: "description",
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.text) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.ctaText &&
        _vm.uiuxTextData.ctaLink &&
        !_vm.uiuxTextData.isVideoCta
          ? _c(
              "a",
              {
                ref: "buttonAnchor",
                attrs: { href: _vm.uiuxTextData.ctaLink }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "form-blue-button",
                    class: this.uiuxTextData.ctaStyle,
                    attrs: {
                      tabindex: "-1",
                      role: "presentation",
                      "aria-label":
                        _vm.uiuxTextData.header + " " + _vm.uiuxTextData.ctaText
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.uiuxTextData.ctaText) +
                        "\n      "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.ctaText && _vm.uiuxTextData.isVideoCta
          ? _c(
              "a",
              {
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function($event) {
                    _vm.openModal()
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.openModal()
                  }
                }
              },
              [
                _c(
                  "button",
                  {
                    ref: "buttonAnchor",
                    staticClass: "form-blue-button",
                    class: this.uiuxTextData.ctaStyle,
                    attrs: {
                      "aria-label":
                        _vm.uiuxTextData.header +
                        " " +
                        _vm.uiuxTextData.ctaText,
                      role: "presentation",
                      tabindex: "-1"
                    }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.uiuxTextData.ctaText) + "\n    "
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { ref: "rightSide", staticClass: "right-side" }, [
        _vm.uiuxImageData.isVideo === true && _vm.uiuxImageData.videoSrc
          ? _c("img", {
              staticClass: "play-video-icon",
              attrs: {
                src: _vm.playIconSrc,
                alt: _vm.uiuxTextData.header + " Play Video",
                tabindex: "0",
                role: "button"
              },
              on: {
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.openModal()
                },
                click: function($event) {
                  _vm.openModal()
                },
                mouseover: _vm.onMouseOver,
                mouseleave: _vm.onMouseLeave
              }
            })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ea90c10", { render: render, staticRenderFns: staticRenderFns })
  }
}