var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "vx-breadcrumb", attrs: { "aria-label": "breadcrumb" } },
    [
      _c(
        "ul",
        { staticClass: "breadcrumb" },
        _vm._l(_vm.breadcrumbs, function(item, index) {
          return _c(
            "li",
            { key: index },
            [
              item.url === "#"
                ? _c("span", {
                    attrs: { "aria-current": "page" },
                    domProps: { innerHTML: _vm._s(item.name) }
                  })
                : [
                    [
                      item.name
                        ? _c("a", {
                            staticClass: "medium-font",
                            attrs: { href: _vm.globals.contextPath + item.url },
                            domProps: { innerHTML: _vm._s(item.name) }
                          })
                        : _vm._e()
                    ]
                  ]
            ],
            2
          )
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0e1b178e", { render: render, staticRenderFns: staticRenderFns })
  }
}