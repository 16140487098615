var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-brand-bar",
      class: _vm.brandBarData.componentTheme
    },
    [
      _vm.brandBarData.header
        ? _c("h2", {
            ref: "header",
            staticClass: "header medium-font",
            domProps: { innerHTML: _vm._s(_vm.brandBarData.header) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.brandBarData.subHeader
        ? _c("div", {
            ref: "description",
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.brandBarData.subHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "brands-carousel" },
        [
          _vm.showNavigationArrows
            ? _c("button", {
                staticClass: "swiper-button-prev icon-chevron-left",
                class: { "swiper-button-disabled": _vm.firstSlide },
                attrs: {
                  tabindex: "0",
                  "aria-label": _vm.i18n.iconChevronLeftTitle,
                  disabled: _vm.firstSlide ? true : false
                },
                on: {
                  click: function($event) {
                    _vm.slidePrev()
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "swiper",
            { attrs: { options: _vm.swiperOptions } },
            _vm._l(_vm.brandBarData.brands, function(brand, index) {
              return _c(
                "swiper-slide",
                { key: index, attrs: { role: "listitem" } },
                [
                  _c("a", {
                    staticClass: "brand",
                    style: {
                      background:
                        "url(" + brand.brandImage + ") no-repeat center white"
                    },
                    attrs: {
                      href: brand.brandLink,
                      "aria-label": brand.brandImageAltText
                    }
                  })
                ]
              )
            })
          ),
          _vm._v(" "),
          _vm.showNavigationArrows
            ? _c("button", {
                staticClass: "swiper-button-next icon-chevron-right",
                class: { "swiper-button-disabled": _vm.lastSlide },
                attrs: {
                  tabindex: "0",
                  "aria-label": _vm.i18n.iconChevronRightTitle,
                  disabled: _vm.lastSlide ? true : false
                },
                on: {
                  click: function($event) {
                    _vm.slideNext()
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-44d86f14", { render: render, staticRenderFns: staticRenderFns })
  }
}