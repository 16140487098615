var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pdpProductInfo.name
    ? _c(
        "section",
        { staticClass: "vx-pdp-product-info row d-flex" },
        [
          _c("vx-spinner", {
            ref: "spinner",
            attrs: {
              image: _vm.globals.assetsPath + "images/spinner.gif",
              "full-screen": ""
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-10 col-md-11 pdp-product-description" },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.pdpProductInfo.name
                    ? [
                        _vm.pdpProductInfo
                          ? _c("h1", {
                              staticClass:
                                "product-name medium-font col-xs-12 h2",
                              domProps: {
                                innerHTML: _vm._s(_vm.pdpProductInfo.name)
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo
                    ? _c("div", { staticClass: "col-xs-12 pdp-item-details" }, [
                        _c("p", [
                          _c("span", { staticClass: "sr-only bold-font" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.itemIdA11y) +
                                " " +
                                _vm._s(_vm.pdpProductInfo.code) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.itemId) +
                                "\n            "
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.pdpProductInfo.code))
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.pdpProductInfo.hasOwnProperty("cmirCode")
                            ? _c("span", { staticClass: "separator" }, [
                                _vm._v("\n            |\n          ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pdpProductInfo.hasOwnProperty("cmirCode")
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.i18n.cmir) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pdpProductInfo.hasOwnProperty("cmirCode")
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.pdpProductInfo.cmirCode) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.siteConfig.enableDisplayAttributes &&
                  _vm.pdpProductInfo.displayAttributes
                    ? _c("div", {
                        staticClass: "display-attributes",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.displayAttributes(
                              _vm.pdpProductInfo.displayAttributes
                            )
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo && _vm.pdpProductInfo.gpCertifications
                    ? _c(
                        "div",
                        { staticClass: "img-container" },
                        [
                          _vm.pdpProductInfo && _vm.isBazaarVoice === "true"
                            ? [
                                _vm.pdpProductInfo.hasOwnProperty(
                                  "bvAverageRating"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "star-rating",
                                        attrs: { id: "star-rating" }
                                      },
                                      [
                                        _c("vx-star-rating", {
                                          attrs: {
                                            "product-rating":
                                              _vm.pdpProductInfo
                                                .bvAverageRating,
                                            "product-reviews":
                                              _vm.pdpProductInfo
                                                .bvNumberOfReviews,
                                            i18n: _vm.i18n,
                                            "show-rating": _vm.showRating,
                                            "show-reviews": _vm.showReviews,
                                            "number-of-stars": 5
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pdpProductInfo.gpCertifications.length > 0
                            ? _c(
                                "div",
                                { staticClass: "certifications" },
                                [
                                  _vm._l(
                                    _vm.pdpProductInfo.gpCertifications,
                                    function(product) {
                                      return [
                                        product.url
                                          ? _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.url,
                                                    target: "_blank",
                                                    title: product.description
                                                  }
                                                },
                                                [
                                                  product.id
                                                    ? _c("img", {
                                                        staticClass: "imgBlock",
                                                        attrs: {
                                                          src:
                                                            product.gpcertificationsImage,
                                                          alt: product.id
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          : _c("div", [
                                              product.id
                                                ? _c("img", {
                                                    staticClass: "imgBlock",
                                                    attrs: {
                                                      title:
                                                        product.description,
                                                      src:
                                                        product.gpcertificationsImage,
                                                      alt: product.id
                                                    }
                                                  })
                                                : _vm._e()
                                            ])
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.isB2B() &&
                  _vm.pdpProductInfo &&
                  _vm.pdpProductInfo.isAvailableForLocation
                    ? _c(
                        "p",
                        { staticClass: "col-xs-12 location-availability" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.i18n.locationAvailable) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo &&
                  _vm.pdpProductInfo.materialStatus ===
                    _vm.ProductAvailability.OBSOLETE &&
                  _vm.pdpProductInfo.replacementProductCode &&
                  _vm.pdpProductInfo.replacementProductCode !== "" &&
                  _vm.globals.siteConfig.showProductStatus
                    ? _c("p", { staticClass: "col-xs-12 px-xs-3 error-text" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/gp/gppro/USD/p/" +
                                _vm.pdpProductInfo.replacementProductCode
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.discontinued) +
                                " " +
                                _vm._s(_vm.i18n.replacedBy) +
                                " " +
                                _vm._s(
                                  _vm.pdpProductInfo.replacementProductCode
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    : _vm.pdpProductInfo &&
                      _vm.pdpProductInfo.materialStatus ===
                        _vm.ProductAvailability.OBSOLETE &&
                      !_vm.pdpProductInfo.replacementProductCode &&
                      _vm.globals.siteConfig.showProductStatus
                    ? _c("p", { staticClass: "col-xs-12 px-xs-3 error-text" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.discontinued) +
                            "\n      "
                        )
                      ])
                    : _vm.pdpProductInfo &&
                      _vm.pdpProductInfo.materialStatus ===
                        _vm.ProductAvailability.COMING_SOON &&
                      _vm.globals.siteConfig.showProductStatus
                    ? _c("p", { staticClass: "col-xs-12 px-xs-3" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.availableSoon) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo.subtitle
                    ? _c("div", {
                        staticClass: "py-xs-2 col-xs-12 product-subtitle",
                        domProps: {
                          innerHTML: _vm._s(_vm.pdpProductInfo.subtitle)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo.sellingstmt
                    ? _c("div", {
                        staticClass: "py-xs-2 col-xs-12 product-selling-smt",
                        domProps: {
                          innerHTML: _vm._s(_vm.pdpProductInfo.sellingstmt)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo.description
                    ? _c(
                        "div",
                        { staticClass: "product-description col-xs-12" },
                        [
                          _vm.pdpProductInfo.description.length < 100
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.pdpProductInfo.description
                                  )
                                }
                              })
                            : _c("span", [
                                !_vm.showFullProductDescription
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.pdpProductInfo.description.substring(
                                            0,
                                            200
                                          ) + "..."
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.showFullProductDescription
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "read-more-text",
                                        attrs: {
                                          tabindex: "0",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.toggleShowFullProductDescription()
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.toggleShowFullProductDescription()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            Read more\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFullProductDescription
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.pdpProductInfo.description.substring(
                                            0
                                          )
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFullProductDescription
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "read-more-text",
                                        attrs: {
                                          tabindex: "0",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.toggleShowFullProductDescription()
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.toggleShowFullProductDescription()
                                          }
                                        }
                                      },
                                      [_vm._v("\n            Less\n          ")]
                                    )
                                  : _vm._e()
                              ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo &&
                  _vm.globals.siteConfig.isProductPriceEnabled &&
                  !_vm.pdpProductInfo.notPurchasable
                    ? _c("div", { staticClass: "py-xs-2 col-xs-12 price" }, [
                        _c(
                          "strong",
                          {
                            staticClass: "current-price medium-font",
                            attrs: {
                              "aria-label":
                                "price" +
                                _vm.pdpProductInfo.price.formattedValue
                            }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.a11yCurrentPrice) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.pdpProductInfo.price.formattedValue
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.pdpProductInfo.weblistPrice &&
                        _vm.globals.siteConfig.isSlashedPriceEnabled
                          ? _c(
                              "span",
                              { staticClass: "pl-xs-2 actual-price" },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(_vm._s(_vm.i18n.a11ySlashedPrice))
                                ]),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.pdpProductInfo.weblistPrice
                                        .formattedValue
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo
                    ? _c(
                        "div",
                        { staticClass: "col-xs-12 pdp-quantity-container" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _vm.globals.siteConfig.isQuantitySelectorEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-4 form-group pdp-stepper-control"
                                  },
                                  [
                                    _c("label", { staticClass: "qty-label" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.i18n.quantity) +
                                          "\n            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("vx-stepper-control", {
                                      attrs: {
                                        value: _vm.quantityValue,
                                        "min-value": _vm.minOrderQuantity(),
                                        "stock-level": _vm.setStockLevel(),
                                        maxValue: _vm.maxOrderQuantity()
                                      },
                                      on: {
                                        currentCount: function($event) {
                                          _vm.getQuantity($event)
                                        },
                                        maxValueUpdated: _vm.maxValueUpdated,
                                        minValueUpdated: _vm.minValueUpdated,
                                        stockLevelUpdated:
                                          _vm.stockLevelUpdated,
                                        disableAddToCart: _vm.disableAddToCart,
                                        spinnerShow: _vm.showSpinnerOnUpdate,
                                        spinnerHide: _vm.hideSpinnerOnUpdate
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.firstVariantEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-8 form-group dropdown"
                                  },
                                  [
                                    _vm.sizeEnabled
                                      ? _c(
                                          "div",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.i18n.size) +
                                                  "\n              "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("vx-dropdown-primary", {
                                              ref: "sizeDropdown",
                                              attrs: {
                                                dropdownValues: _vm.sizeList
                                              },
                                              on: {
                                                "selected-item": function(
                                                  $event
                                                ) {
                                                  _vm.updateSize($event)
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.verifyProductAvailability()
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-xs-12 pdp-quantity-container"
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _vm.globals.siteConfig
                                                .isQuantitySelectorEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-xs-12 col-sm-12 col-md-4 form-group pdp-stepper-control"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "qty-label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .quantity
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("vx-stepper-control", {
                                                        attrs: {
                                                          value:
                                                            _vm.quantityValue,
                                                          "min-value": _vm.minOrderQuantity(),
                                                          "stock-level": _vm.setStockLevel(),
                                                          maxValue: _vm.maxOrderQuantity()
                                                        },
                                                        on: {
                                                          currentCount: function(
                                                            $event
                                                          ) {
                                                            _vm.getQuantity(
                                                              $event
                                                            )
                                                          },
                                                          maxValueUpdated:
                                                            _vm.maxValueUpdated,
                                                          minValueUpdated:
                                                            _vm.minValueUpdated,
                                                          stockLevelUpdated:
                                                            _vm.stockLevelUpdated,
                                                          disableAddToCart:
                                                            _vm.disableAddToCart,
                                                          spinnerShow:
                                                            _vm.showSpinnerOnUpdate,
                                                          spinnerHide:
                                                            _vm.hideSpinnerOnUpdate
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.firstVariantEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-xs-12 col-sm-12 col-md-8 form-group dropdown"
                                                    },
                                                    [
                                                      _vm.sizeEnabled
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .size
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "vx-dropdown-primary",
                                                                {
                                                                  ref:
                                                                    "sizeDropdown",
                                                                  attrs: {
                                                                    dropdownValues:
                                                                      _vm.sizeList
                                                                  },
                                                                  on: {
                                                                    "selected-item": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.updateSize(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.countEnabled
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .count
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "vx-dropdown-primary",
                                                                {
                                                                  ref:
                                                                    "countsDropdown",
                                                                  attrs: {
                                                                    dropdownValues:
                                                                      _vm.countsList
                                                                  },
                                                                  on: {
                                                                    "selected-item": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.updateCounts(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.scentEnabled
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .scent
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "vx-dropdown-primary",
                                                                {
                                                                  ref:
                                                                    "scentDropdown",
                                                                  attrs: {
                                                                    dropdownValues:
                                                                      _vm.scentList
                                                                  },
                                                                  on: {
                                                                    "selected-item": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.updateScent(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pdpProductInfo &&
                                    _vm.secondVariantEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-xs-12 pdp-facet-container"
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _vm.sizeEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-sm-12 col-md-6 form-group dropdown"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.i18n.size
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "vx-dropdown-primary",
                                                        {
                                                          ref: "sizeDropdown",
                                                          attrs: {
                                                            dropdownValues:
                                                              _vm.sizeList
                                                          },
                                                          on: {
                                                            "selected-item": function(
                                                              $event
                                                            ) {
                                                              _vm.updateSize(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.countEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-sm-12 col-md-6 form-group dropdown"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(_vm.i18n.count)
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "vx-dropdown-primary",
                                                        {
                                                          ref: "countsDropdown",
                                                          attrs: {
                                                            dropdownValues:
                                                              _vm.countsList
                                                          },
                                                          on: {
                                                            "selected-item": function(
                                                              $event
                                                            ) {
                                                              _vm.updateCounts(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.scentEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-sm-12 col-md-6 form-group dropdown"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(_vm.i18n.scent)
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "vx-dropdown-primary",
                                                        {
                                                          ref: "scentDropdown",
                                                          attrs: {
                                                            dropdownValues:
                                                              _vm.scentList
                                                          },
                                                          on: {
                                                            "selected-item": function(
                                                              $event
                                                            ) {
                                                              _vm.updateScent(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pdpProductInfo &&
                                    _vm.pdpProductInfo.stock.stockLevel &&
                                    _vm.pdpProductInfo.isSubscribable &&
                                    _vm.pdpProductInfo.subscriptionPrice &&
                                    _vm.pdpProductInfo.subsPercentageDiscount &&
                                    _vm.pdpProductInfo.materialStatus !==
                                      _vm.ProductAvailability.OBSOLETE &&
                                    _vm.pdpProductInfo.stock
                                      .stockLevelStatus !==
                                      _vm.ProductAvailability.OUT_OF_STOCK
                                      ? _c(
                                          "div",
                                          { staticClass: "col-xs-12 mb-xs-3" },
                                          [
                                            _vm._l(
                                              _vm.subscriptionChecked,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-group",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.radioButtonOptionFeature(
                                                          item,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.subscriptionCheckedItems,
                                                          expression:
                                                            "subscriptionCheckedItems"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-check-input",
                                                      attrs: {
                                                        tabindex: "0",
                                                        name:
                                                          "subscriptionCheckedItems",
                                                        type: "radio",
                                                        label: item.label,
                                                        "aria-label": item.label
                                                      },
                                                      domProps: {
                                                        value: item.value,
                                                        checked: _vm._q(
                                                          _vm.subscriptionCheckedItems,
                                                          item.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.subscriptionCheckedItems =
                                                            item.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-check-label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(item.label) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c("vx-dropdown-primary", {
                                              ref:
                                                "subscriptionFrequencyDropdown",
                                              attrs: {
                                                dropdownValues:
                                                  _vm.subscriptionFrequency
                                              },
                                              on: {
                                                "selected-item": function(
                                                  $event
                                                ) {
                                                  _vm.userChosenFrequency = $event
                                                },
                                                primaryDropdownMounted: function(
                                                  $event
                                                ) {
                                                  _vm.handleSubscriptionFrequencyDefaultValue()
                                                }
                                              }
                                            })
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pdpProductInfo &&
                                    ((_vm.pdpProductInfo.stock
                                      .stockLevelStatus ===
                                      _vm.ProductAvailability.OUT_OF_STOCK &&
                                      _vm.pdpProductInfo.materialStatus !==
                                        _vm.ProductAvailability.OBSOLETE) ||
                                      _vm.pdpProductInfo.materialStatus ===
                                        _vm.ProductAvailability.COMING_SOON) &&
                                    _vm.globals.siteConfig.showInventoryMessages
                                      ? _c(
                                          "div",
                                          { staticClass: "col-xs-12 mb-xs-3" },
                                          [
                                            !_vm.pdpProductInfo.stock.hasOwnProperty(
                                              "nextAvailableDate"
                                            ) ||
                                            _vm.pdpProductInfo
                                              .materialStatus ===
                                              _vm.ProductAvailability
                                                .COMING_SOON
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-tertiary notify-me-btn",
                                                    on: {
                                                      click: _vm.handleNotifyMe
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.i18n
                                                            .notifyAvailability
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.scentEnabled
                                      ? _c(
                                          "div",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.i18n.scent) +
                                                  "\n              "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("vx-dropdown-primary", {
                                              ref: "scentDropdown",
                                              attrs: {
                                                dropdownValues: _vm.scentList
                                              },
                                              on: {
                                                "selected-item": function(
                                                  $event
                                                ) {
                                                  _vm.updateScent($event)
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo && _vm.secondVariantEnabled
                    ? _c(
                        "div",
                        { staticClass: "col-xs-12 pdp-facet-container" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _vm.sizeEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-12 col-md-6 form-group dropdown"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.i18n.size) +
                                          "\n            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("vx-dropdown-primary", {
                                      ref: "sizeDropdown",
                                      attrs: { dropdownValues: _vm.sizeList },
                                      on: {
                                        "selected-item": function($event) {
                                          _vm.updateSize($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.countEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-12 col-md-6 form-group dropdown"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.i18n.count))
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("vx-dropdown-primary", {
                                      ref: "countsDropdown",
                                      attrs: { dropdownValues: _vm.countsList },
                                      on: {
                                        "selected-item": function($event) {
                                          _vm.updateCounts($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.scentEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-12 col-md-6 form-group dropdown"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.i18n.scent))
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("vx-dropdown-primary", {
                                      ref: "scentDropdown",
                                      attrs: { dropdownValues: _vm.scentList },
                                      on: {
                                        "selected-item": function($event) {
                                          _vm.updateScent($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row pdp-add-to-cart-container cart-sticky-bottom"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-xs-12 px-xs-3 py-xs-2" },
                                [
                                  _vm.verifyProductAvailability()
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "add-to-cart-btn bold-font form-blue-button",
                                          class: {
                                            disabled:
                                              _vm.addToCartDisabled ||
                                              !_vm.soldIndividually ||
                                              _vm.pdpProductInfo.notPurchasable
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.addToCartDisabled ||
                                              !_vm.soldIndividually ||
                                              _vm.pdpProductInfo.notPurchasable,
                                            "aria-label": _vm.i18n.addToCart
                                          },
                                          on: {
                                            click: _vm.handleButtonTextClick
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.i18n.addToCart) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("vx-dropdown-primary", {
                            ref: "subscriptionFrequencyDropdown",
                            attrs: {
                              dropdownValues: _vm.subscriptionFrequency
                            },
                            on: {
                              "selected-item": function($event) {
                                _vm.userChosenFrequency = $event
                              },
                              primaryDropdownMounted: function($event) {
                                _vm.handleSubscriptionFrequencyDefaultValue()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo &&
                  _vm.styleOptionsObj &&
                  _vm.styleOptionsArr.length > 0
                    ? _c("div", { staticClass: "col-xs-12" }, [
                        _c("div", { staticClass: "row form-group" }, [
                          _c("label", { staticClass: "col-xs-12" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.variant) +
                                " (" +
                                _vm._s(_vm.styleVariantName) +
                                ")\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.styleOptionsArr.length > 0
                            ? _c(
                                "ul",
                                {
                                  staticClass: "col-xs-12 pdp-variant-container"
                                },
                                _vm._l(_vm.styleOptionsArr, function(ref) {
                                  var variant = ref.variantOptionQualifiers
                                  var isSelected = ref.isSelected
                                  var stock = ref.stock
                                  var url = ref.url
                                  return _c(
                                    "li",
                                    { staticClass: "pr-xs-3 variant" },
                                    _vm._l(variant, function(variants) {
                                      return _c("span", [
                                        variants.swatchImageUrl
                                          ? _c(
                                              "a",
                                              {
                                                class: { active: isSelected },
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                  title: _vm.getVariantName(
                                                    variants.value,
                                                    isSelected
                                                  ),
                                                  "aria-label":
                                                    "open variant" +
                                                    variants.value
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.openSelectedVariant(
                                                      url,
                                                      variants
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      variants.swatchImageUrl,
                                                    alt: variants.value
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.verifyIfProductShouldbeCrossed(
                                                  stock,
                                                  variants
                                                )
                                                  ? _c("span", {
                                                      staticClass: "icon-cross"
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        variants.hexCode &&
                                        !variants.swatchImageUrl
                                          ? _c(
                                              "a",
                                              {
                                                class: { active: isSelected },
                                                style: {
                                                  background: variants.hexCode
                                                },
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                  title: _vm.getVariantName(
                                                    variants.value,
                                                    isSelected
                                                  ),
                                                  "aria-label":
                                                    "open variant" +
                                                    variants.value
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.openSelectedVariant(
                                                      url,
                                                      variants
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.verifyIfProductShouldbeCrossed(
                                                  stock,
                                                  variants
                                                )
                                                  ? _c("span", {
                                                      staticClass: "icon-cross"
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    })
                                  )
                                })
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.verifyIfNotifymeTobeDisplayed()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 mb-xs-3 mx-xs-3 notify-me-container"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-tertiary notify-me-btn",
                              on: { click: _vm.handleNotifyMe }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.notifyAvailability) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "find-a-distributor other-ways-button",
                              attrs: { "aria-label": _vm.i18n.otherWaysToBuy },
                              on: { click: _vm.handleOtherwaysToBuy }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.otherWaysToBuy) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.verifyIfNotifymeTobeDisplayed()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 mb-xs-3 px-xs-8 notify-me-container"
                        },
                        [
                          !_vm.isFavorites
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "add-to-list wishlist-btn bold-font",
                                  on: {
                                    click: function($event) {
                                      _vm.handleSelectList($event)
                                    },
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter-space",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.handleSelectList($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { tabindex: "0", role: "button" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.i18n.addToList) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo &&
                  _vm.pdpProductInfo.hasOwnProperty("maxOrderQuantity") &&
                  !_vm.pdpProductInfo.hasOwnProperty("minOrderQuantity") &&
                  _vm.globals.siteConfig.showInventoryMessages
                    ? _c("p", { staticClass: "col-xs-12 max-quantity-info" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.maxQuantity) +
                            "\n        " +
                            _vm._s(_vm.pdpProductInfo.maxOrderQuantity) +
                            ".\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo &&
                  _vm.pdpProductInfo.hasOwnProperty("minOrderQuantity") &&
                  _vm.globals.siteConfig.showInventoryMessages
                    ? _c("p", { staticClass: "col-xs-12 max-quantity-info" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.minQuantity) +
                            "\n        " +
                            _vm._s(_vm.pdpProductInfo.minOrderQuantity) +
                            ".\n      "
                        )
                      ])
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.verifyIfAddtocartTobeDispalyed()
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row pdp-add-to-cart-container cart-sticky-bottom"
                    },
                    [
                      _c("div", { staticClass: "col-sm-12 px-xs-3 py-xs-2" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "add-to-cart-btn bold-font form-blue-button",
                            class: {
                              disabled: _vm.shouldAddTocartBeDisabled()
                            },
                            attrs: {
                              disabled: _vm.shouldAddTocartBeDisabled(),
                              "aria-label": _vm.i18n.addToCart
                            },
                            on: { click: _vm.handleButtonTextClick }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.addToCart) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "find-a-distributor other-ways-button",
                            attrs: { "aria-label": _vm.i18n.otherWaysToBuy },
                            on: { click: _vm.handleOtherwaysToBuy }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.otherWaysToBuy) +
                                "\n        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-12 px-xs-8 py-xs-2" }, [
                        !_vm.isFavorites
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "add-to-list wishlist-btn bold-font",
                                on: {
                                  click: function($event) {
                                    _vm.handleSelectList($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleSelectList($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { tabindex: "0", role: "button" } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.i18n.addToList) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "add-to-list-find-a-distributor-mobile-container"
                },
                [
                  _c("div", { staticClass: "find-a-distributor" }, [
                    _c(
                      "button",
                      {
                        staticClass: "other-ways-button mx-xs-3",
                        class: { disabled: _vm.shouldAddTocartBeDisabled() },
                        attrs: { "aria-label": _vm.i18n.otherWaysToBuy },
                        on: { click: _vm.handleOtherwaysToBuy }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.otherWaysToBuy) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isFavorites
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "add-to-list wishlist-btn bold-font mx-xs-3 mt-3",
                          attrs: { role: "button" },
                          on: {
                            click: function($event) {
                              _vm.handleSelectList($event)
                            },
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter-space",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              _vm.handleSelectList($event)
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.addToList) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.siteConfig.pdpLocateAStoreEnabled
                    ? _c(
                        "div",
                        { staticClass: "row pdp-add-to-cart-container" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-12 px-xs-3 py-xs-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "add-to-cart-btn bold-font",
                                  attrs: { "aria-label": _vm.i18n.findAStore },
                                  on: { click: _vm.findAStore }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.i18n.findAStore) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.pdpProductInfo &&
              _vm.pdpProductInfo.isLeaseable &&
              _vm.globals.siteConfig.showInventoryMessages
                ? _c("div", { staticClass: "pdp-lease-container" }, [
                    _c("span", {
                      staticClass: "icon-alert-circle",
                      attrs: { title: _vm.i18n.iconAlertCircleTitle }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "pl-xs-2" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.i18n.leaseAgreement) +
                          "\n      "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.siteConfig.isReferAFriend
                ? _c("span", { attrs: { id: "extole_zone_product_page" } })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-2 col-xs-2 col-md-1 pdp-product-cta" },
            [
              _vm.isFavorites
                ? _c(
                    "div",
                    {
                      staticClass: "tile-icon wishlist-btn mb-xs-3 mb-sm-4",
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": "Favorites"
                      },
                      on: {
                        click: function($event) {
                          _vm.toggleFav($event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.toggleFav($event)
                        }
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "favorite",
                          class: { active: _vm.isActive },
                          attrs: {
                            title: _vm.isActive
                              ? _vm.i18n.iconSavedToFavoriteTitle
                              : _vm.i18n.iconSaveToFavoriteTitle,
                            "aria-label": _vm.isActive
                              ? _vm.i18n.iconSavedToFavoriteTitle
                              : _vm.i18n.iconSaveToFavoriteTitle
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "heart",
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                viewBox: "0 0 25 25",
                                preserveAspectRatio: "none",
                                width: "20",
                                height: "20"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Page-1",
                                    stroke: "none",
                                    "stroke-width": "2",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "heart-outline",
                                        transform:
                                          "translate(1.000000, 0.000000),scale(0.75, 0.8)",
                                        stroke: "#999"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "fill",
                                          d:
                                            "M22.3634,0.9763 C19.9054,0.9763 17.1654,1.9943 15.9764,5.2263 C15.8984,5.4373 15.5944,5.4293 15.5214,5.2163 C14.4144,1.9913 11.5714,0.9763 9.1154,0.9763 C4.4974,0.9763 0.7394,5.0363 0.7394,10.0263 C0.7394,12.3713 1.5684,14.5963 3.0754,16.2903 L15.0314,28.2673 C15.4224,28.6583 16.0564,28.6593 16.4484,28.2673 L28.1794,16.5343 C29.8234,14.8303 30.7394,12.5113 30.7394,10.0263 C30.7394,5.0363 26.9814,0.9763 22.3634,0.9763",
                                          fill: "#ffffff"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.globals.isB2B()
                ? _c("vx-share-item", {
                    attrs: {
                      slot: "component",
                      url: _vm.prodUrl,
                      title: _vm.title,
                      "is-social-share": this.isSocialShare
                    },
                    slot: "component"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.guestListName
                ? [
                    _c("vx-save-cart", {
                      attrs: {
                        prodQuantity: _vm.quantityValue,
                        analyticsObjects: _vm.analyticsObject,
                        "cart-guid": _vm.prodId,
                        i18n: _vm.i18n,
                        "is-product": "true",
                        "guest-list-name": _vm.guestListName
                      },
                      on: { "list-saved": _vm.onSelectListSuccess }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.globals.isB2B()
            ? _c(
                "vx-modal",
                {
                  ref: "shareItemModal",
                  attrs: { size: "large", heading: _vm.i18n.shareItemHeading }
                },
                [
                  _c("vx-share-item", {
                    attrs: {
                      slot: "component",
                      productCode: _vm.prodId,
                      url: _vm.prodUrl,
                      i18n: _vm.i18n,
                      "product-info": _vm.pdpProductInfo,
                      "certifications-name": _vm.certificationsName
                    },
                    on: {
                      "share-item-success": _vm.onShareItemSuccess,
                      "share-item-error": _vm.onShareItemError
                    },
                    slot: "component"
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "selectListModal",
              attrs: { size: "small", heading: _vm.i18n.selectListHeading }
            },
            [
              _c("vx-save-cart", {
                attrs: {
                  slot: "component",
                  prodQuantity: _vm.quantityValue,
                  analyticsObjects: _vm.analyticsObject,
                  "cart-guid": _vm.prodId,
                  i18n: _vm.i18n,
                  "is-product": "true"
                },
                on: { "list-saved": _vm.onSelectListSuccess },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "notifyMeModal",
              attrs: { size: "extra-small", heading: _vm.i18n.notifyMeHeading }
            },
            [
              _c("vx-notify-me", {
                attrs: {
                  slot: "component",
                  title: _vm.i18n.notifyMeContent,
                  productCode: _vm.prodId,
                  i18n: _vm.i18n
                },
                on: {
                  "notify-me-success": _vm.onNotifyMeSuccess,
                  "notify-me-error": _vm.onNotifyMeError
                },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "refillsModal",
              attrs: { size: "large", heading: _vm.i18n.refillsHeading }
            },
            [
              _c("vx-refills", {
                attrs: {
                  slot: "component",
                  "enable-sample-cart": _vm.isSampleCart,
                  products: _vm.refillProductsData,
                  i18n: _vm.i18n,
                  "is-favorites": _vm.isFavorites,
                  "is-bazaar-voice": _vm.isBazaarVoice,
                  "search-browse-i18n": _vm.searchBrowseI18n
                },
                on: {
                  "refills-error": _vm.onRefillsModalError,
                  "refills-success": _vm.onRefillsModalSuccess,
                  "refills-no-thanks": _vm.onRefillsNoThanks
                },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "downloadInfoModal",
              attrs: { size: "large", heading: _vm.i18n.downloadInfoHeading }
            },
            [
              _c("vx-download-info", {
                attrs: {
                  slot: "component",
                  "is-pdp": true,
                  i18n: _vm.i18n,
                  "color-codes": _vm.colorCodes,
                  "is-image-download-enabled":
                    _vm.globals.siteConfig.downloadPdpImage,
                  "is-pdf-download-enabled":
                    _vm.globals.siteConfig.downloadPdpPdf,
                  certificationsName: _vm.certificationsName
                },
                on: {
                  onImageDownloadInit: function($event) {
                    _vm.onImageDownloadInit($event)
                  },
                  onPdfDownloadInit: function($event) {
                    _vm.createPDF($event)
                  }
                },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "asmModal",
              attrs: { size: "small", heading: _vm.i18n.asmHeading },
              on: { close: _vm.modalClose }
            },
            [
              _c(
                "div",
                {
                  staticClass: "asm-message",
                  attrs: { slot: "component" },
                  slot: "component"
                },
                [_vm._v("\n      " + _vm._s(_vm.i18n.asmMessage) + "\n    ")]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-124bded9", { render: render, staticRenderFns: staticRenderFns })
  }
}