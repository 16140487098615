var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-double-cta-banner row",
      class: _vm.ctaBannerData.componentTheme
    },
    [
      _c("div", { ref: "textBox", staticClass: "text-box" }, [
        _c("div", { staticClass: "welcome-text bold-font" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.ctaBannerData.highlighterText) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "description-text" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.ctaBannerData.subHeaderText) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-container" }, [
          _c(
            "a",
            { attrs: { href: _vm.ctaBannerData.ctaLink, target: "_self" } },
            [
              _c(
                "button",
                {
                  ref: "loginButton",
                  staticClass: "login-button form-blue-button bold-font",
                  class: this.ctaBannerData.ctaStyle,
                  attrs: { role: "presentation", tabindex: "-1" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.ctaBannerData.ctaText) +
                      "\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: _vm.ctaBannerData.ctaLink2, target: "_self" } },
            [
              _c(
                "button",
                {
                  ref: "connectWithUsButton",
                  staticClass:
                    "connect-with-us-button form-blue-button bold-font",
                  attrs: { role: "presentation", tabindex: "-1" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.ctaBannerData.ctaText2) +
                      "\n                "
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ae42a30", { render: render, staticRenderFns: staticRenderFns })
  }
}