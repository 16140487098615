var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-news-knowledge", class: _vm.componentTheme },
    [
      _vm.componentTitle
        ? _c("h2", {
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.componentTitle) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tabsData.length > 1
        ? _c(
            "div",
            { staticClass: "tabs", attrs: { role: "tablist" } },
            _vm._l(_vm.tabsData, function(tab) {
              return _c(
                "div",
                {
                  staticClass: "tab",
                  class: {
                    selected:
                      _vm.selectedCategory ===
                      tab.gpMarketingCategoryData.marketingCategoryHeader
                  },
                  attrs: {
                    role: "tab",
                    tabindex:
                      _vm.selectedCategory ===
                      tab.gpMarketingCategoryData.marketingCategoryHeader
                        ? 0
                        : -1,
                    "aria-selected":
                      _vm.selectedCategory ===
                      tab.gpMarketingCategoryData.marketingCategoryHeader
                        ? "true"
                        : "false"
                  },
                  on: {
                    click: function($event) {
                      _vm.selectCategory(
                        tab.gpMarketingCategoryData.marketingCategoryHeader
                      )
                    },
                    keyup: [
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.selectCategory(
                          tab.gpMarketingCategoryData.marketingCategoryHeader
                        )
                      },
                      function($event) {
                        _vm.arrowNavigation($event)
                      }
                    ]
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        tab.gpMarketingCategoryData.marketingCategoryHeader
                      ) +
                      "\n    "
                  )
                ]
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.tabsData, function(category) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  category.gpTipsAndKnowledgeData.length > 0 &&
                  _vm.selectedCategory ===
                    category.gpMarketingCategoryData.marketingCategoryHeader,
                expression:
                  "category.gpTipsAndKnowledgeData.length > 0 && selectedCategory === category.gpMarketingCategoryData.marketingCategoryHeader"
              }
            ],
            staticClass: "category",
            class: _vm.replaceSpaceWithDash(
              category.gpMarketingCategoryData.marketingCategoryHeader
            )
          },
          _vm._l(_vm.reverseArray(category.gpTipsAndKnowledgeData), function(
            article
          ) {
            return _c("div", { staticClass: "article" }, [
              _c("a", {
                staticClass: "left",
                style: {
                  background:
                    "url(" + article.tipImageUrl + ") no-repeat center"
                },
                attrs: {
                  href: article.tipLinkUrl,
                  "aria-label": article.tipText
                },
                on: { keydown: _vm.focusedElement }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                article.tipText
                  ? _c("div", {
                      staticClass: "header",
                      domProps: { innerHTML: _vm._s(article.tipText) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                article.description
                  ? _c("div", {
                      staticClass: "description",
                      domProps: { innerHTML: _vm._s(article.description) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                article.tipLinkUrl && article.tipLinkText
                  ? _c("span", { staticClass: "link-container" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: article.tipLinkUrl,
                            target: article.external ? "_blank" : "_self",
                            "aria-label":
                              article.tipText + " " + article.tipLinkText
                          },
                          on: { keydown: _vm.focusedElement }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(article.tipLinkText) +
                              "\n        "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          })
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "show-additional-articles",
          attrs: { role: "button" },
          on: {
            click: function($event) {
              _vm.showAdditionalArticles()
            },
            keyup: function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.showAdditionalArticles()
            }
          }
        },
        [_c("span", { attrs: { tabindex: "0" } }, [_vm._v("SHOW MORE")])]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c4c4660", { render: render, staticRenderFns: staticRenderFns })
  }
}