var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      { staticClass: "header-section navbar-fixed-top non-printable-section" },
      [
        _c("vx-spinner", {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        }),
        _vm._v(" "),
        _c(
          "vx-modal",
          {
            ref: "forgotPasswordModal",
            attrs: { heading: _vm.registrationLogin.login.passwordModalHeading }
          },
          [
            _c("vx-forgot-password", {
              attrs: { slot: "component", i18n: _vm.registrationLogin.login },
              slot: "component"
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.isTablet() &&
        _vm.headerOptions.promoTxt &&
        _vm.isPromoEnabled &&
        !_vm.headerOptions.isCheckout
          ? _c(
              "div",
              {
                staticClass:
                  "py-xs-2 px-xs-3 px-md-0 promo-section m-xs-0 text-center promo-tablet"
              },
              [
                _vm.headerOptions.promoTxt && _vm.headerOptions.promoUrl
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.headerOptions.isExternalLink
                            ? _vm.headerOptions.promoUrl
                            : _vm.globals.getUrlWithContextPath(
                                _vm.headerOptions.promoUrl
                              ),
                          target: _vm.headerOptions.isExternalLink
                            ? "_blank"
                            : ""
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$options.filters.maxCharacters(
                              _vm.headerOptions.promoTxt
                            )
                          )
                        )
                      ]
                    )
                  : _vm.headerOptions.promoTxt
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$options.filters.maxCharacters(
                              _vm.headerOptions.promoTxt
                            )
                          ) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "nav",
          {
            staticClass:
              "navbar-bottom d-flex align-items-center justify-content-between",
            class: { "checkout-header": _vm.headerOptions.isCheckout },
            attrs: { role: "navigation" }
          },
          [
            _c(
              "div",
              { staticClass: "header-left-section d-flex align-items-center" },
              [
                _c(
                  "button",
                  {
                    staticClass: "icon-menu ml-xs-3 py-xs-3",
                    attrs: {
                      href: "javascript:void(0)",
                      role: "button",
                      tabindex: "0",
                      title: _vm.i18n.iconMenuTitle,
                      "aria-label": _vm.i18n.iconMenuTitle,
                      "aria-expanded": _vm.showMobileNav ? "true" : "false"
                    },
                    on: {
                      click: function($event) {
                        _vm.showMobileNav = !_vm.showMobileNav
                        _vm.updateAria(_vm.showMobileNav)
                        _vm.setHeaderHeight()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Main Navigation")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.isHomePage
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "logo",
                          attrs: { itemscope: "", tabindex: "0" },
                          on: {
                            click: function($event) {
                              _vm.globals.navigateToUrl("home")
                            }
                          }
                        },
                        [
                          _vm.globals.siteConfig.isBrandLogoMobile &&
                          _vm.isTablet()
                            ? [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.globals.assetsPath +
                                      _vm.headerOptions.brandMobileLogo,
                                    alt: _vm.globals.getSiteName()
                                  }
                                })
                              ]
                            : [
                                _vm.globals.siteConfig.showCheckoutLogo &&
                                _vm.headerOptions.isCheckout
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.checkoutLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  : _vm.unitHeaderLogo
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.unitHeaderLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.brandLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                              ]
                        ],
                        2
                      )
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "logo",
                          attrs: { tabindex: "0" },
                          on: {
                            click: function($event) {
                              _vm.globals.navigateToUrl("home")
                            }
                          }
                        },
                        [
                          _vm.globals.siteConfig.isBrandLogoMobile &&
                          _vm.isTablet()
                            ? [
                                _vm.globals.siteConfig.showCheckoutLogo &&
                                _vm.headerOptions.isCheckout
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.checkoutLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.brandMobileLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                              ]
                            : [
                                _vm.globals.siteConfig.showCheckoutLogo &&
                                _vm.headerOptions.isCheckout
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.checkoutLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  : _vm.unitHeaderLogo
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.unitHeaderLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.brandLogo,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                              ]
                        ],
                        2
                      )
                    ],
                _vm._v(" "),
                !_vm.headerOptions.isCheckout
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "non-printable-section navbar-bottom-left d-flex pl-xs-0 pl-md-4",
                        class: { "mobile-view": _vm.showMobileNav },
                        on: { click: _vm.dismissMobileMenu }
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "navigationMenu",
                            staticClass:
                              "navigation-menu d-flex align-items-center pt-xs-3 pt-md-0",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "navigation-inner-wrapper" },
                              [
                                _vm.showMobileNav
                                  ? _c(
                                      "div",
                                      { staticClass: "pb-xs-2 pb-md-0" },
                                      [_vm._m(0)]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "pb-xs-2 pb-md-0" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "m-xs-0 primary-menu align-self-center d-flex pl-xs-0",
                                      attrs: { role: "list" }
                                    },
                                    _vm._l(_vm.navMenu, function(item, index) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass:
                                            "primary-menu-item js-primary-menu-item p-md-3",
                                          attrs: { role: "listitem" },
                                          on: {
                                            mouseover: _vm.displayDropDownMenu,
                                            mouseleave: _vm.hideDropDownMenu
                                          }
                                        },
                                        [
                                          item.primary
                                            ? [
                                                _vm.showMobileNav &&
                                                _vm.isSecondaryAvailable(item)
                                                  ? _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.selectedPrimary ===
                                                              "",
                                                            expression:
                                                              "selectedPrimary === ''"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "p-xs-3 p-md-0",
                                                        attrs: {
                                                          href: "#",
                                                          tabindex: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.selectPrimary(
                                                              item.primary
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.primary
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : [
                                                      item.external
                                                        ? _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.selectedPrimary ===
                                                                    "",
                                                                  expression:
                                                                    "selectedPrimary === ''"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "p-xs-3 p-md-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.primary
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "a",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.selectedPrimary ===
                                                                    "",
                                                                  expression:
                                                                    "selectedPrimary === ''"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "p-xs-3 p-md-0",
                                                              attrs: {
                                                                href:
                                                                  _vm.globals
                                                                    .contextPath +
                                                                  item.primaryLink,
                                                                tabindex: "0"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.primary
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          !_vm.isTablet()
                                            ? _c("span", {
                                                staticClass:
                                                  "icon-chevron-down",
                                                attrs: {
                                                  "aria-controls": _vm._f(
                                                    "lowercase"
                                                  )(item.primary),
                                                  tabindex: "0",
                                                  "aria-label": _vm._f(
                                                    "lowercase"
                                                  )("Open " + item.primary),
                                                  role: "button"
                                                },
                                                on: {
                                                  click:
                                                    _vm.displayDropDownMenu,
                                                  keyup: [
                                                    function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.hideDropDownMenu(
                                                        $event
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !("button" in $event) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.displayDropDownMenu(
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          !_vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu pt-xs-5 px-md-6 pb-md-5",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  },
                                                  attrs: {
                                                    id: _vm._f("lowercase")(
                                                      item.primary
                                                    )
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category"
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.returnItemsImages(
                                                              secondaryItem.menuOptions
                                                            ),
                                                            function(image) {
                                                              return _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      image.link,
                                                                    target: image.isExternalLink
                                                                      ? "_blank"
                                                                      : "_self"
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "featured-image pl-xs-3",
                                                                    attrs: {
                                                                      src:
                                                                        image.imageSrcD,
                                                                      alt:
                                                                        image.imageSrcAltTextD
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          secondaryItem.title
                                                            ? [
                                                                _c("h2", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title js-secondary-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role: "list"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    },
                                                                    attrs: {
                                                                      role:
                                                                        "listitem"
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              },
                                                                              on: {
                                                                                keyup: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !(
                                                                                      "button" in
                                                                                      $event
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "esc",
                                                                                      27,
                                                                                      $event.key,
                                                                                      "Escape"
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return _vm.hideDropDownMenu(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          _vm.selectedPrimary ===
                                            item.primary &&
                                            _vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu mobile pt-xs-3 px-md-4 pt-md-4 pb-md-2",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.showMobileNav
                                                    ? _c("a", {
                                                        staticClass:
                                                          "icon-chevron-left ml-xs-3 px-xs-0 js-secondary-action-el",
                                                        attrs: {
                                                          tabindex: "0",
                                                          role: "button",
                                                          "aria-label": "Back",
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.focusParent(
                                                              _vm.selectedPrimary,
                                                              _vm.navMenu
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category"
                                                        },
                                                        [
                                                          secondaryItem.title
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role: "list"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    },
                                                                    attrs: {
                                                                      role:
                                                                        "listitem"
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              },
                                                                              on: {
                                                                                keyup: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !(
                                                                                      "button" in
                                                                                      $event
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "esc",
                                                                                      27,
                                                                                      $event.key,
                                                                                      "Escape"
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return _vm.hideDropDownMenu(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    })
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.showMobileNav
                                  ? _c(
                                      "div",
                                      { staticClass: "pb-xs-2 pb-md-0" },
                                      [
                                        !_vm.globals.getIsLoggedIn() &&
                                        _vm.globals.siteConfig
                                          .isMobileMyProfileEnabled
                                          ? _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "my-account-text pl-xs-3 pt-xs-2",
                                                attrs: {
                                                  "aria-hidden":
                                                    _vm.selectedPrimary !== ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.i18n.loginSectionTitle
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "pl-xs-0",
                                            class: {
                                              "login-mobile-view":
                                                _vm.showMobileNav
                                            },
                                            attrs: {
                                              "aria-hidden":
                                                _vm.selectedPrimary !== ""
                                            }
                                          },
                                          [
                                            _vm.headerOptions.showLoginRegister
                                              ? _vm._l(_vm.loginMenu, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "p-md-3 js-primary-menu-item",
                                                      attrs: { role: "link" }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.selectedPrimary ===
                                                                "",
                                                              expression:
                                                                "selectedPrimary === ''"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "p-xs-3 p-md-0 custom-word-wrap-all",
                                                          attrs: {
                                                            href: item.external
                                                              ? item.optionLink
                                                              : _vm.globals
                                                                  .contextPath +
                                                                item.optionLink,
                                                            target: item.external
                                                              ? "_blank"
                                                              : "_self",
                                                            tabindex: "0"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.option
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              : _vm._l(_vm.loginMenu, function(
                                                  item
                                                ) {
                                                  return !(
                                                    ~item.optionLink.indexOf(
                                                      "login"
                                                    ) ||
                                                    ~item.optionLink.indexOf(
                                                      "register"
                                                    )
                                                  )
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "p-md-3 js-primary-menu-item",
                                                          attrs: {
                                                            tabindex: "0"
                                                          }
                                                        },
                                                        [
                                                          _c("a", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.selectedPrimary ===
                                                                  "",
                                                                expression:
                                                                  "selectedPrimary === ''"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "p-xs-3 p-md-0 custom-word-wrap-all",
                                                            attrs: {
                                                              href: item.external
                                                                ? item.optionLink
                                                                : _vm.globals
                                                                    .contextPath +
                                                                  item.optionLink,
                                                              target: item.external
                                                                ? "_blank"
                                                                : "_self",
                                                              tabindex: "0"
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.option
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                })
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _vm.findMenu &&
                                        (_vm.globals.siteConfig
                                          .isFindADistributorEnabled ||
                                          _vm.globals.siteConfig
                                            .isFindAStoreEnabled)
                                          ? _c(
                                              "ul",
                                              {
                                                staticClass: "pl-xs-0",
                                                class: {
                                                  "login-mobile-view":
                                                    _vm.showMobileNav
                                                },
                                                attrs: {
                                                  role: "presentation",
                                                  "aria-hidden":
                                                    _vm.selectedPrimary !== ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "p-md-3 js-primary-menu-item",
                                                    attrs: { tabindex: "0" }
                                                  },
                                                  [
                                                    _c("a", {
                                                      staticClass:
                                                        "p-xs-3 p-md-0 custom-word-wrap-all",
                                                      attrs: {
                                                        href: _vm.findMenu
                                                          .external
                                                          ? _vm.findMenu
                                                              .optionLink
                                                          : _vm.globals
                                                              .contextPath +
                                                            _vm.findMenu
                                                              .optionLink,
                                                        target: _vm.findMenu
                                                          .external
                                                          ? "_blank"
                                                          : "_self",
                                                        tabindex: "0"
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.findMenu.option
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "pb-xs-2 pb-md-0" })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            !_vm.headerOptions.isCheckout
              ? _c(
                  "div",
                  {
                    staticClass:
                      "non-printable-section d-flex navbar-bottom-right justify-content-between align-items-center",
                    attrs: {
                      "aria-hidden": _vm.showMobileNav && _vm.isTablet()
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "navbar-bottom-right-icons d-flex align-items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _vm.globals.isB2B() &&
                            _vm.headerOptions.showQuickOrder
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "quick-order ml-md-5",
                                    attrs: {
                                      href: _vm.globals.getNavigationUrl(
                                        "quickOrder"
                                      ),
                                      "aria-label": _vm.i18n.iconQuickOrderTitle
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "icon-quick-order",
                                      attrs: {
                                        title: _vm.i18n.iconQuickOrderTitle
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.getLoginContainerVisibility()
                              ? _c("div", { staticClass: "login-container" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "login-icon",
                                      attrs: { title: "Login" },
                                      on: {
                                        click: function($event) {
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          ) {
                                            return null
                                          }
                                          _vm.openGpProLoginModal()
                                        }
                                      }
                                    },
                                    [
                                      _vm.showLoginModal
                                        ? _c(
                                            "div",
                                            { staticClass: "secondary-menu" },
                                            [
                                              _c("div", {
                                                staticClass: "arrow-up",
                                                class: [
                                                  {
                                                    "search-input-shown":
                                                      _vm.showSearch
                                                  },
                                                  _vm.shownLoginForm
                                                ]
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "top-section" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tabs" },
                                                    [
                                                      _vm.shownLoginForm ===
                                                      "gppro"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                        LOGIN TO GPPRO\n                      "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.shownLoginForm ===
                                                      "gpexpress"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                        LOGIN TO GPXPRESS®\n                      "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.shownLoginForm ===
                                                            "gppro",
                                                          expression:
                                                            "shownLoginForm === 'gppro'"
                                                        }
                                                      ],
                                                      staticClass: "gp-pro-text"
                                                    },
                                                    [_vm._m(1)]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.shownLoginForm ===
                                                            "gpexpress",
                                                          expression:
                                                            "shownLoginForm === 'gpexpress'"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "gp-express-text"
                                                    },
                                                    [_vm._m(2)]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-section"
                                                },
                                                [
                                                  _c(
                                                    "form",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.shownLoginForm ===
                                                            "gppro",
                                                          expression:
                                                            "shownLoginForm === 'gppro'"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "gp-pro-login",
                                                      attrs: {
                                                        action:
                                                          "/gp/gppro/USD/j_spring_security_check",
                                                        method: "post"
                                                      }
                                                    },
                                                    [
                                                      _c("fieldset", [
                                                        _vm._m(3),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "email col-md-6"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              E-MAIL\n                            "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.gpProLoginUsername,
                                                                      expression:
                                                                        "gpProLoginUsername"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      "j_username",
                                                                    type:
                                                                      "email",
                                                                    maxlength:
                                                                      "80",
                                                                    placeholder:
                                                                      "E-MAIL"
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm.gpProLoginUsername
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.gpProLoginUsername =
                                                                        $event.target.value
                                                                    }
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "remember-me"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              _vm.gpProLoginRememberMe,
                                                                            expression:
                                                                              "gpProLoginRememberMe"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          id:
                                                                            "gppro-login-checkbox",
                                                                          type:
                                                                            "checkbox",
                                                                          "aria-labeledby":
                                                                            "remember-box-1"
                                                                        },
                                                                        domProps: {
                                                                          checked: Array.isArray(
                                                                            _vm.gpProLoginRememberMe
                                                                          )
                                                                            ? _vm._i(
                                                                                _vm.gpProLoginRememberMe,
                                                                                null
                                                                              ) >
                                                                              -1
                                                                            : _vm.gpProLoginRememberMe
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$a =
                                                                                _vm.gpProLoginRememberMe,
                                                                              $$el =
                                                                                $event.target,
                                                                              $$c = $$el.checked
                                                                                ? true
                                                                                : false
                                                                            if (
                                                                              Array.isArray(
                                                                                $$a
                                                                              )
                                                                            ) {
                                                                              var $$v = null,
                                                                                $$i = _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                              if (
                                                                                $$el.checked
                                                                              ) {
                                                                                $$i <
                                                                                  0 &&
                                                                                  (_vm.gpProLoginRememberMe = $$a.concat(
                                                                                    [
                                                                                      $$v
                                                                                    ]
                                                                                  ))
                                                                              } else {
                                                                                $$i >
                                                                                  -1 &&
                                                                                  (_vm.gpProLoginRememberMe = $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    ))
                                                                              }
                                                                            } else {
                                                                              _vm.gpProLoginRememberMe = $$c
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          "aria-label":
                                                                            "Remember Me",
                                                                          id:
                                                                            "remember-box-1",
                                                                          for:
                                                                            "gppro-login-checkbox"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                Remember Me\n                              "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "password col-md-6"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              PASSWORD\n                            "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "password-input-container"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        ref:
                                                                          "gpproPassword",
                                                                        attrs: {
                                                                          name:
                                                                            "j_password",
                                                                          type:
                                                                            "password",
                                                                          maxlength:
                                                                            "255",
                                                                          placeholder:
                                                                            "PASSWORD"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "icon-eye toggle-password",
                                                                      class: {
                                                                        "icn-eye-collapsed":
                                                                          _vm.showGpProPassword
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.toggleGpProPassword()
                                                                        }
                                                                      }
                                                                    })
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "forgot-password",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.handleForgotPassword()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              Forgot Password?\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "hidden",
                                                          name: "baseSiteId"
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.globals.siteId
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "hidden",
                                                          name: "CSRFToken"
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.globals
                                                              .csrfToken
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm._m(4)
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.shownLoginForm ===
                                                            "gpexpress",
                                                          expression:
                                                            "shownLoginForm === 'gpexpress'"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "col-md-12 button-container"
                                                    },
                                                    [
                                                      _vm._m(5),
                                                      _vm._v(" "),
                                                      _vm._m(6)
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "login-text" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "login-link-container login-top",
                                        attrs: { tabindex: "0" }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "icon-user"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "gp-pro",
                                            on: {
                                              click: function($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                _vm.openGpProLoginModal()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.i18n.gpproLogin) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "login-link-container login-bottom",
                                        attrs: { tabindex: "0" }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "icon-user"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "gp-express",
                                            on: {
                                              click: function($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                _vm.openGpExpressLoginModal()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.i18n.gpExpressLogin
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.globals.getIsLoggedIn()
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "profile-container",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      mouseover: _vm.displayProfile,
                                      mouseleave: _vm.hideProfile,
                                      keyup: [
                                        function($event) {
                                          if (
                                            !("button" in $event) &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.displayProfile($event)
                                        },
                                        function($event) {
                                          if (
                                            !("button" in $event) &&
                                            _vm._k(
                                              $event.keyCode,
                                              "esc",
                                              27,
                                              $event.key,
                                              "Escape"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.hideProfile($event)
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "profile-icon icon-user" },
                                      [
                                        _vm.showProfile
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "secondary-menu px-xs-4 pt-xs-4 pb-xs-3",
                                                on: {
                                                  mouseover: function($event) {
                                                    _vm.isProfileHovered = true
                                                  },
                                                  mouseleave: function($event) {
                                                    _vm.isProfileHovered = false
                                                  }
                                                }
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "arrow-up",
                                                  class: {
                                                    "search-input-shown":
                                                      _vm.showSearch
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "first-line" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "customer-name"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              this.globals
                                                                .userInfo
                                                                .firstName
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              this.globals
                                                                .userInfo
                                                                .lastName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/gp/my-account/profile",
                                                      title:
                                                        _vm.i18n
                                                          .profileAndPreferences
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n
                                                            .profileAndPreferences
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/gp/my-account/addresses",
                                                      title: _vm.i18n.addresses
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n.addresses
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/gp/my-account/paymentdetails",
                                                      title:
                                                        _vm.i18n.paymentMethods
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n
                                                            .paymentMethods
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      title: _vm.i18n.myLists,
                                                      tabindex: "0"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.navigateTomyLists()
                                                      },
                                                      keyup: function($event) {
                                                        if (
                                                          !(
                                                            "button" in $event
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        _vm.navigateTomyLists()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n.myLists
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/gp/my-account/orders",
                                                      title:
                                                        _vm.i18n.orderHistory
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n.orderHistory
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/gp/my-account/supportTickets",
                                                      title:
                                                        _vm.i18n.supportTickets
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n
                                                            .supportTickets
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/gp/logout"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Logout\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "profile-text",
                                        attrs: { href: "/my-account/profile" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Hi, " +
                                            _vm._s(
                                              _vm.globals.userInfo.firstName
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.headerOptions.includeSearchBox
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showSearch,
                                        expression: "showSearch"
                                      }
                                    ],
                                    staticClass: "search-container",
                                    class: {
                                      "align-search": !_vm.headerOptions
                                        .includeMiniCart
                                    }
                                  },
                                  [
                                    _vm._t(
                                      "search",
                                      [
                                        _vm._v(
                                          "\n                Default body\n              "
                                        )
                                      ],
                                      { toggleSearchIcon: _vm.toggleSearchIcon }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "headerMyLists",
                                attrs: {
                                  "aria-label": _vm.i18n.myLists,
                                  tabindex: "0",
                                  role: "link"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.navigateTomyLists()
                                  }
                                }
                              },
                              [_vm._v("My\n              Lists")]
                            ),
                            _vm._v(" "),
                            _vm.headerOptions.includeMiniCart
                              ? _c("div", { staticClass: "cart" }, [
                                  _c("a", {
                                    staticClass: "icon-cart",
                                    attrs: {
                                      href: _vm.globals.getNavigationUrl(
                                        "cart"
                                      ),
                                      title:
                                        _vm.i18n.cartWith +
                                        _vm.cartItems +
                                        _vm.i18n.items
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.showMiniCart
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "header-mini-cart hidden-xs hidden-sm visible-md visible-lg",
                                          on: {
                                            mouseover: function($event) {
                                              _vm.isMiniCartHovered = true
                                            },
                                            mouseleave: function($event) {
                                              _vm.isMiniCartHovered = false
                                            }
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "arrow-up"
                                          }),
                                          _vm._v(" "),
                                          _vm._t("cart", [
                                            _vm._v(
                                              "\n                  Default body\n                "
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.cartItems
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "badge",
                                          on: {
                                            click: function($event) {
                                              _vm.globals.navigateToUrl("cart")
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.cartItems) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "assistive-text",
                                      attrs: {
                                        "aria-live": "assertive",
                                        "aria-atomic": "true"
                                      }
                                    },
                                    [
                                      _vm.cartItems
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.i18n.cartItemsText) +
                                                " " +
                                                _vm._s(_vm.cartItems) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.headerOptions.isCheckout
              ? _c(
                  "div",
                  {
                    staticClass:
                      "checkout-options d-flex justify-content-end pr-md-4"
                  },
                  _vm._l(_vm.checkoutMenu.menuOptions, function(item) {
                    return _c("a", {
                      staticClass: "px-xs-3 py-xs-3",
                      attrs: {
                        href: item.external
                          ? item.optionLink
                          : _vm.globals.contextPath + item.optionLink
                      },
                      domProps: { innerHTML: _vm._s(item.option) }
                    })
                  })
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.i18n.clarCartModal
          ? _c(
              "vx-modal",
              {
                ref: "profileSwitchModal",
                attrs: {
                  size: "medium",
                  heading: _vm.i18n.clarCartModal.warningText
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "profileSwitchModal",
                    attrs: { slot: "component" },
                    slot: "component"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.clarCartModal.cartClearMessage) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary modal-btn-primary-medium modal-btn-xs-sticky",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.clearCart($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.clarCartModal.continueBtnText) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "printable-section printable-image" }, [
      _vm.globals.siteConfig.isBrandLogoMobile && _vm.isTablet()
        ? _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandMobileLogo
            }
          })
        : _vm.unitHeaderLogo
        ? _c("img", {
            attrs: { alt: _vm.globals.getSiteName(), src: _vm.unitHeaderLogo }
          })
        : _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandLogo
            }
          })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "m-xs-0 primary-menu align-self-center d-flex pl-xs-0",
        attrs: { role: "list" }
      },
      [
        _c(
          "li",
          {
            staticClass: "primary-menu-item js-primary-menu-item p-md-3",
            attrs: { role: "listitem" }
          },
          [
            _c("a", { staticClass: "p-xs-3 p-md-0", attrs: { href: "/" } }, [
              _vm._v("\n                      Home\n                    ")
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(
        "\n                        GP PRO direct customer can create an account "
      ),
      _c("span", { staticClass: "bold-blue" }, [
        _vm._v(
          "to purchase the latest GP\n                          PRO products, access past orders, create product lists"
        )
      ]),
      _vm._v(
        ", and more! Everything customers\n                        need is in one place, under one login\n                      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(
        "\n                        GPXpress® is GP PRO's one source for distribution operations, sales and marketing. This platform\n                        helps bring "
      ),
      _c("span", { staticClass: "bold-blue" }, [
        _vm._v(
          "efficiency,\n                          transparency and knowledgeable support to the order and fulfillment journey"
        )
      ]),
      _vm._v(
        ". Member can\n                        manage pricing, view order\n                        statuses in real time, live chat more!\n                      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Login")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "button",
          {
            staticClass: "login-cta-button blue-button",
            attrs: { type: "submit" }
          },
          [
            _vm._v(
              "\n                            LOG IN\n                          "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "a",
          {
            staticClass: "login-cta-button white-button link-button",
            attrs: { href: "/gp/gppro/USD/register" }
          },
          [
            _vm._v(
              "\n                            CREATE AN ACCOUNT\n                          "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c(
        "a",
        {
          staticClass:
            "login-cta-button blue-button link-button register-button",
          attrs: { type: "submit", href: "http://gpxpr.es/", tabindex: "5" }
        },
        [_vm._v("\n                          LOG IN\n                        ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c(
        "a",
        {
          staticClass:
            "login-cta-button white-button link-button register-button",
          attrs: {
            type: "submit",
            href: "https://gpxpr.es/Register",
            tabindex: "6"
          }
        },
        [
          _vm._v(
            "\n                          REGISTER\n                        "
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ba20f65c", { render: render, staticRenderFns: staticRenderFns })
  }
}