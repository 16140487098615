var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-text-side-subcats", class: _vm.componentTheme },
    [
      _c("div", { staticClass: "left-side-outer" }, [
        _c("div", { staticClass: "left-side-inner" }, [
          _vm.imageTextData.header
            ? _c("h2", {
                ref: "header",
                staticClass: "header bold-font",
                domProps: { innerHTML: _vm._s(_vm.imageTextData.header) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imageTextData.text
            ? _c("div", {
                ref: "description",
                staticClass: "description",
                domProps: { innerHTML: _vm._s(_vm.imageTextData.text) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imageTextData.ctaText
            ? _c("a", { attrs: { href: _vm.imageTextData.ctaLink } }, [
                _c(
                  "button",
                  {
                    ref: "button",
                    staticClass: "form-blue-button bold-font",
                    class: this.imageTextData.ctaStyle,
                    attrs: {
                      "aria-label":
                        _vm.imageTextData.ctaText.substr(5) +
                        " brand products page",
                      role: "presentation",
                      tabindex: "-1"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.imageTextData.ctaText) +
                        "\n                "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right-side" }, [
        _vm.imageTextData.subImageTiles[0] || _vm.imageTextData.subImageTiles[1]
          ? _c("div", { staticClass: "tiles" }, [
              _vm.imageTextData.subImageTiles[0]
                ? _c("div", { staticClass: "tile" }, [
                    _c("img", {
                      class: {
                        "is-link":
                          _vm.imageTextData.subImageTiles[0].imageData.imageLink
                      },
                      attrs: {
                        src:
                          _vm.imageTextData.subImageTiles[0].imageData
                            .imageSrcD,
                        alt:
                          _vm.imageTextData.subImageTiles[0].imageData
                            .imageSrcAltTextD
                      },
                      on: {
                        click: function($event) {
                          _vm.goToLink(
                            _vm.imageTextData.subImageTiles[0].imageData
                              .imageLink
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "tile-label",
                        attrs: {
                          href: _vm.imageTextData.subImageTiles[0].ctaLink,
                          "aria-label":
                            _vm.imageTextData.ctaText.substr(5) +
                            " brand " +
                            _vm.imageTextData.subImageTiles[0].ctaText.substr(
                              5
                            ) +
                            " page"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.imageTextData.subImageTiles[0].ctaText) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.imageTextData.subImageTiles[1]
                ? _c("div", { staticClass: "tile" }, [
                    _c("img", {
                      class: {
                        "is-link":
                          _vm.imageTextData.subImageTiles[1].imageData.imageLink
                      },
                      attrs: {
                        src:
                          _vm.imageTextData.subImageTiles[1].imageData
                            .imageSrcD,
                        alt:
                          _vm.imageTextData.subImageTiles[1].imageData
                            .imageSrcAltTextD
                      },
                      on: {
                        click: function($event) {
                          _vm.goToLink(
                            _vm.imageTextData.subImageTiles[1].imageData
                              .imageLink
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "tile-label",
                        attrs: {
                          href: _vm.imageTextData.subImageTiles[1].ctaLink,
                          "aria-label":
                            _vm.imageTextData.ctaText.substr(5) +
                            " brand " +
                            _vm.imageTextData.subImageTiles[1].ctaText.substr(
                              5
                            ) +
                            " page"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.imageTextData.subImageTiles[1].ctaText) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.imageTextData.subImageTiles[2]
                ? _c("div", { staticClass: "tile" }, [
                    _c("img", {
                      class: {
                        "is-link":
                          _vm.imageTextData.subImageTiles[2].imageData.imageLink
                      },
                      attrs: {
                        src:
                          _vm.imageTextData.subImageTiles[2].imageData
                            .imageSrcD,
                        alt:
                          _vm.imageTextData.subImageTiles[2].imageData
                            .imageSrcAltTextD
                      },
                      on: {
                        click: function($event) {
                          _vm.goToLink(
                            _vm.imageTextData.subImageTiles[2].imageData
                              .imageLink
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "tile-label",
                        attrs: {
                          href: _vm.imageTextData.subImageTiles[2].ctaLink,
                          "aria-label":
                            _vm.imageTextData.ctaText.substr(5) +
                            " brand " +
                            _vm.imageTextData.subImageTiles[2].ctaText.substr(
                              5
                            ) +
                            " page"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.imageTextData.subImageTiles[2].ctaText) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d9dde2c", { render: render, staticRenderFns: staticRenderFns })
  }
}