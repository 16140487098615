var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-widebanner-right-cta",
      class: [
        { reverse: _vm.uiuxImageData.imageAlignment === "right" },
        _vm.componentTheme
      ]
    },
    [
      _c("lazy-component", { on: { show: _vm.onLazyLoad } }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "leftSide",
          staticClass: "left",
          class: {
            "is-video": _vm.uiuxImageData.isVideo && _vm.uiuxImageData.videoSrc
          }
        },
        [
          _vm.uiuxImageData.isVideo && _vm.uiuxImageData.videoSrc
            ? _c("img", {
                staticClass: "play-video-icon",
                attrs: {
                  src: _vm.playIconSrc,
                  alt: _vm.uiuxTextData.header + " Play Video",
                  role: "button",
                  tabindex: "0"
                },
                on: {
                  click: function($event) {
                    _vm.openModal()
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.openModal()
                  },
                  mouseover: _vm.onMouseOver,
                  mouseleave: _vm.onMouseLeave
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _vm.uiuxTextData.superHeaderBody
          ? _c("div", {
              ref: "superHeader",
              staticClass: "super-header",
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.superHeaderBody) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.header
          ? _c("div", {
              ref: "header",
              staticClass: "header",
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.header) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.text
          ? _c("div", {
              ref: "description",
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.text) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.bulletedList
          ? _c("div", {
              domProps: { innerHTML: _vm._s(_vm.uiuxTextData.bulletedList) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.ctaLink &&
        _vm.uiuxTextData.ctaText &&
        !_vm.uiuxTextData.isVideoCta
          ? _c("div", { staticClass: "cta-container" }, [
              _c(
                "a",
                { ref: "link", attrs: { href: _vm.uiuxTextData.ctaLink } },
                [
                  _c(
                    "button",
                    {
                      ref: "linkButton",
                      staticClass: "form-blue-button",
                      class: this.uiuxTextData.ctaStyle,
                      attrs: {
                        tabindex: "-1",
                        "aria-label":
                          _vm.uiuxTextData.header +
                          " " +
                          _vm.uiuxTextData.ctaText,
                        role: "presentation"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.uiuxTextData.ctaText) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.uiuxTextData.ctaText && _vm.uiuxTextData.isVideoCta
          ? _c(
              "a",
              {
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: function($event) {
                    _vm.openModal()
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.openModal()
                  }
                }
              },
              [
                _c(
                  "button",
                  {
                    ref: "linkButton",
                    staticClass: "form-blue-button",
                    class: this.uiuxTextData.ctaStyle,
                    attrs: {
                      "aria-label":
                        _vm.uiuxTextData.header +
                        " " +
                        _vm.uiuxTextData.ctaText,
                      role: "presentation",
                      tabindex: "-1"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.openModal()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.uiuxTextData.ctaText) + "\n    "
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-49185e70", { render: render, staticRenderFns: staticRenderFns })
  }
}