/* global JsBarcode */

import { jsPDF } from 'jspdf';

import VueBarcode from 'vue-barcode';
import globals from '../globals';
// import certificates from '../../../../assets/base64/certificates-icons';
import imgBlob from '../../../../assets/base64/gppro-icons';
import { generatePdf } from '../mixins/vx-enums';
import gothicBold from '../../../../assets/fonts/gp-pro/GothamBold-bold';
import gothicLight from '../../../../assets/fonts/gp-pro/GothamLight-normal';

export default {
  name: 'vx-pdf-generator',
  components: {
    barcode: VueBarcode,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    pdfData: {
      type: Object,
      required: true,
    },
    hasSustainabilityPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      imgBlob: imgBlob.noImage,
      gothicBold,
      gothicLight,
      // Default export is a4 paper, portrait, using milimeters for units
      pdf: {},
      pdfDefaults: {
        barColor: '#000000',
        headingColor: '#000000',
        headerTextColorList: '#000000',
        headerTextColor: '#FFFFFF',
        borderColor: '#FAFAFA',
        footerTextColor: '#222222',
        footerBackgroundColor: '#CECECE',
        subFooterBgColor: '#FFFFFF',
        timeStampTextColor: '#FFFFFF',
        footerText: '© 2020 Georgia-Pacific Consumer Products LP. All rights reserved.',
        textColor: '#222222',
      },
      pdpProductInfo: {},
      coverPageBlob: '',
      globals,
      headingTextBoxHeight: 0,
      firstPage: true,
      generatePdf,
      featuredData: [],
      isFullDetail: false,
      uncheckedData: [],
      counter: 0,
      maxFullDetail: false,
      data: {},
      pdfInfo: {},
    };
  },
  mounted() {
  },
  methods: {
    /**
     * Resets PDF page
     */
    resetDimensions() {
      this.headingTextBoxHeight = 0;
      this.firstPage = true;
      this.featuredData = [];
    },
    /**
     * Renders Header of PDF
     */
    createHeader(customData) {
      this.pdf.setFontSize(12);
      // Render Header Top Bar
      if (customData.isPdp) {
        this.pdf.setFillColor(0, 82, 147);
        this.pdf.setTextColor(this.pdfDefaults.headerTextColor);
      } else {
        this.pdf.setFillColor(255, 255, 255);
        this.pdf.setTextColor(this.pdfDefaults.headerTextColorList);
      }
      this.pdf.rect(0, 0, 600, 55, 'F');
      if (customData.headerText && !customData.isPdp) {
        this.pdf.setFontSize(10);
        if (customData.headerLogo) {
          this.pdf.addImage(customData.headerLogo, 'PNG', 16, 18, 120, 40);
        }
        const headerText = customData.headerText.split('*');
        this.pdf.setFont(gothicBold, 'bold');
        this.pdf.text(headerText[0], 300, 20, {
          align: 'left',
        });
        this.pdf.setFont(gothicLight, 'normal');
        this.pdf.text([headerText[1], headerText[2]], 300, 32, {
          align: 'left',
        });
      } else {
        // Render site logo
        // Changes for YCOMM-13800
        if (customData.distributorImage) {
          this.pdf.addImage(customData.distributorImage, 'PNG', 16, 8, 0, 40);
        } else if (customData.headerLogo) {
          this.pdf.addImage(customData.headerLogo, 'PNG', 16, 8, 120, 40);
        }
        // else if (!customData.isPdp) {
        //   if (customData.headerLogo) {
        //     this.pdf.addImage(customData.headerLogo, 'PNG', 16, 8, 120, 40);
        //   }
        // } else {
        //   this.pdf.addImage(imgBlob.gpProLogo, 'PNG', 16, 8, 40, 40);
        // }
        if (customData.unitCompany || customData.unitAddress || customData.b2bphoneNumber || customData.b2bWebsite) {
          this.pdf.setFontSize(10);
          this.pdf.setFont(gothicBold, 'bold');
          this.pdf.text(customData.unitCompany, 300, 20, {
            align: 'center',
          });
          this.pdf.setFont(gothicLight, 'normal');
          const phoneNumberNwebsite = [
            ...customData.b2bphoneNumber ? [customData.b2bphoneNumber] : [],
            ...customData.b2bWebsite ? [customData.b2bWebsite] : [],
          ];
          const unitAddPhoneWebsite = [
            ...customData.unitAddress ? [customData.unitAddress] : [],
            ...phoneNumberNwebsite ? [phoneNumberNwebsite.join(' | ')] : [],
          ];
          this.pdf.text(unitAddPhoneWebsite, 300, customData.unitCompany ? 32 : 20, {
            align: 'center',
          });
        } else if (!customData.distributorImage) {
          this.pdf.setFontSize(10);
          this.pdf.setFont(gothicBold, 'bold');
          this.pdf.text('GP PRO', 400, 20, {
            align: 'left',
          });
          this.pdf.setFont(gothicLight, 'normal');
          this.pdf.text(['1-866-HELLO GP (435-5647)', 'www.gppro.com'], 400, 32, {
            align: 'left',
          });
        }
      }

      // Render header Strip
      if (customData.barColor && customData.barColor !== '') {
        this.pdf.setFillColor(customData.barColor);
      } else {
        this.pdf.setFillColor(this.pdfDefaults.barColor);
      }
      this.pdf.rect(0, 55, 600, 10, 'F');
    },

    /**
     * Renders Sub Header
     * @param {object} customData
     */
    createSubHeader(customData) {
      if (customData.headlineLine1 || customData.headlineLine2) {
        this.headingTextBoxHeight = 50;
        // Render Heading box
        if (customData.headlineColor !== '') {
          this.pdf.setTextColor(customData.headlineColor);
        } else {
          this.pdf.setTextColor(this.pdfDefaults.headingColor);
        }
        this.pdf.setFillColor(this.pdfDefaults.borderColor);
        this.pdf.rect(0, 65, 600, 50, 'F');
        if (customData.headlineLine1) {
          this.pdf.setFontSize(14);
          this.pdf.text(customData.headlineLine1, 25, 80, {
            align: 'left',
          });
        }
        if (customData.headlineLine2) {
          this.pdf.setFontSize(12);
          this.pdf.text(customData.headlineLine2, 25, 100, {
            align: 'left',
          });
        }
        // Render heading text bottom strip
        if (customData.barColor !== '') {
          this.pdf.setFillColor(customData.barColor);
        } else {
          this.pdf.setFillColor(this.pdfDefaults.barColor);
        }
        this.pdf.rect(0, 110, 600, 3, 'F');
      }
    },
    /**
     * Used for appending the dimensions in the product name
     * @param {Array} specification
     * @param {string} name
     * @param {Number} left
     * @param {Number} productTop
     * @param {Number} productSplitSize
     * @param {Number} productNameSize
     * @param {Number} minNameHeight
     * @param {Number} sizeFactor
     */
    detailedProductName(specifications, name, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor) {
      if (specifications && specifications.length) {
        const productName = `${name}, ${specifications}`;
        const nameHeight = this.appendProductName(productName, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor);
        productTop += nameHeight;
        return productTop;
      }
      const productName = `${name}`;
      const nameHeight = this.appendProductName(productName, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor);
      productTop += nameHeight;
      return productTop;
    },
    /**
     * Used for Splitting the product names to different rows
     * @param {string} productName
     * @param {Number} left
     * @param {Number} productTop
     * @param {Number} productSplitSize
     * @param {Number} productNameSize
     * @param {Number} minNameHeight
     * @param {Number} sizeFactor
     */
    appendProductName(productName, left, productTop, productSplitSize, productNameSize, minNameHeight) {
      const productNameBreak = this.pdf.splitTextToSize(productName, productSplitSize);
      this.pdf.text(left + 50, productTop, productNameBreak);
      return (productNameBreak.length * minNameHeight);
      // returns the amount in which gpCertification icons will be offset from productName (initially minNameHeight)
      // multiplied by how many lines productName has (productNameBreak.length)
    },
    /**
     * Renders Footer
     * @param {object} customData
     */
    createFooter(customData) {
      const dateObj = new Date();
      const printedDate = `Printed: ${`0${dateObj.getMonth() + 1}`.slice(
        -2,
      )}/${`0${dateObj.getDate()}`.slice(-2)}/${dateObj.getFullYear()}`;
      if (customData.barColor && customData.barColor !== '') {
        this.pdf.setFillColor(customData.barColor);
      } else {
        this.pdf.setFillColor(this.pdfDefaults.barColor);
      }
      this.pdf.rect(0, 765, 600, 10, 'F');
      this.pdf.setTextColor(this.pdfDefaults.timeStampTextColor);
      this.pdf.setFontSize(8);
      this.pdf.text(printedDate, 510, 772);
      // Render Sub Footer
      this.pdf.setFillColor(this.pdfDefaults.subFooterBgColor);
      this.pdf.setTextColor(this.pdfDefaults.footerTextColor);
      this.pdf.rect(0, 775, 600, 50, 'F');
      this.pdf.setFontSize(8);
      this.pdf.setLineWidth(0.1);
      this.pdf.line(150, 780, 150, 820);
      let subFooterTop = 785;
      if (customData.nameOnPdf && customData.nameOnPdf !== '') {
        this.pdf.text(customData.nameOnPdf, 340, subFooterTop, {
          align: 'center',
        });
        subFooterTop += 10;
      }
      const emailAddress = !customData.emailAddress ? '' : customData.emailAddress;
      let phoneNumber = '';
      if (customData.phoneNumber && customData.phoneNumber !== '') {
        const phonefir = customData.phoneNumber.substring(0, 3);
        const phoneSec = customData.phoneNumber.substring(3, 6);
        const phoneThird = customData.phoneNumber.substring(6);
        phoneNumber = `+1 ${phonefir}-${phoneSec}-${phoneThird}`;
      }
      this.pdf.text(
        `${
          emailAddress
        }  ${phoneNumber}`,
        340,
        subFooterTop,
        {
          align: 'center',
        },
      );
      subFooterTop += 10;
      if (customData.message && customData.message !== '') {
        this.pdf.text(customData.message, 340, subFooterTop, {
          align: 'center',
        });
        subFooterTop += 14;
      }
      // Render Footer
      this.pdf.setFontSize(8);
      this.pdf.setFillColor(this.pdfDefaults.footerBackgroundColor);
      this.pdf.rect(0, 825, 600, 16, 'F');
      this.pdf.setTextColor(this.pdfDefaults.footerTextColor);
      this.pdf.text(this.pdfDefaults.footerText, 280, 835, {
        align: 'center',
      });
      if (customData.distributorImage) {
        if (customData.headerLogo) {
          this.pdf.addImage(customData.headerLogo, 'PNG', 16, 781, 120, 40);
        }
      }
    },
    /**
     * Adds coverpage to the pdf where key is sustainability
     * @param {object} pdfInfo
     */
    renderSustainability(pdfInfo) {
      const width = this.pdf.internal.pageSize.getWidth();
      const height = this.pdf.internal.pageSize.getHeight();
      let sustainabilityImage = '';
      pdfInfo.pdfCoverImages.entry.forEach((coverPage) => {
        if (coverPage.key === 'sustainability') {
          sustainabilityImage = coverPage.value;
        }
      });
      this.pdf.addPage();
      this.pdf.addImage(
        `data:image/jpeg;base64,${sustainabilityImage}`,
        this.getExtension(sustainabilityImage),
        0,
        0,
        width,
        height,
      );
    },
    /**
     * Add Coverpage image to the pdf based on the coverpage dropdown value selection
     * @param {object} pdfInfo
     */
    renderCoverPage(pdfInfo) {
      const width = this.pdf.internal.pageSize.getWidth();
      const height = this.pdf.internal.pageSize.getHeight();
      let coverImage = '';
      pdfInfo.pdfCoverImages.entry.forEach((coverPage) => {
        if (pdfInfo.coverPage === coverPage.key) {
          coverImage = coverPage.value;
        }
      });
      this.pdf.addImage(
        `data:image/jpeg;base64,${coverImage}`,
        this.getExtension(coverImage),
        0,
        0,
        width,
        height,
      );
      this.pdf.addPage();
    },
    /**
     * Save PDF with a given name
     * @param {object} pdfInfo
     */
    savePdf(pdfInfo) {
      if (this.hasSustainabilityPage) {
        this.renderSustainability(pdfInfo);
      }
      this.pdf.save(`${this.name}.pdf`);
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
    },
    /**
     * Creates a PDF instance
     */
    createPDFInstance() {
      /* eslint-disable-next-line new-cap */
      this.pdf = new jsPDF('p', 'pt', 'a4');
    },
    /**
     * Create PDF based on information given for PDP
     * @param {object} pdfInfo
     */
    createPdfPDPFormat(pdfInfo) {
      this.pdfInfo = pdfInfo;
      this.createPDFInstance();
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.pdpProductInfo = this.data;
      const fromPdp = true;
      this.createHeader(pdfInfo);
      this.createSubHeader(pdfInfo);
      this.createFooter(pdfInfo);
      this.createLeftSection(fromPdp);
      this.createRightSection(fromPdp);
      this.savePdf(pdfInfo);
    },
    /**
     * Creates PDF for PDP
     * @param {object} pdfInfo
     */
    createPdfPDPBase64(pdfInfo) {
      this.pdfInfo = pdfInfo;
      this.createPDFInstance();
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.pdpProductInfo = this.data;
      const fromPdp = true;
      this.createHeader({ isPdp: fromPdp });
      this.createSubHeader({});
      this.createFooter({ isPdp: fromPdp });
      this.createLeftSection(fromPdp);
      this.createRightSection(fromPdp);
      if (this.hasSustainabilityPage) {
        this.renderSustainability();
      }
      return btoa(this.pdf.output());
    },
    /**
     * Create PDF based on information given for List
     * @param {object} pdfInfo
     */
    createListPdfPDPFormat(pdfInfo) {
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.pdfInfo = pdfInfo;
      this.resetDimensions();
      this.createPDFInstance();
      const productLength = this.data.wishlistEntries.length;
      if (pdfInfo.coverPage) {
        this.renderCoverPage(pdfInfo);
      }
      this.data.wishlistEntries.forEach((pdpProductInfo, index) => {
        this.pdpProductInfo = pdpProductInfo.product;
        this.pdpProductInfo.customAttr1Value = pdpProductInfo.customAttr1Value;
        this.pdpProductInfo.customAttr2Value = pdpProductInfo.customAttr2Value;
        this.pdpProductInfo.customAttr3Value = pdpProductInfo.customAttr3Value;
        this.createHeader(pdfInfo);
        if (pdfInfo.onlyOnFirstPage && this.firstPage) {
          this.createSubHeader(pdfInfo);
          this.firstPage = false;
        } else if (!pdfInfo.onlyOnFirstPage) {
          this.createSubHeader(pdfInfo);
        } else {
          this.headingTextBoxHeight = 0;
        }
        this.createFooter(pdfInfo);
        this.createLeftSection();
        this.createRightSection();
        if (productLength === index + 1) {
          this.savePdf(pdfInfo);
        } else {
          this.pdf.addPage();
        }
      });
    },
    createPdfBulkPdp() {},
    /**
     * Reads a file on load and on end makes a call back to the URl
     * @param {string} url
     * @param {Function} callback
     * @param {boolean} flag
     */
    toDataURL(url, callback, flag) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result, flag);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    /**
     * Used for convrting Blob to image
     * @param {object} response
     * @param {string} type
     */
    updateImageSrc(response, type) {
      if (type === generatePdf.imgBlob) {
        this.imgBlob = response;
      }
    },
    /**
     * Appends certification image to the PDF
     * @param {string} key
     * @param {object} pdfInfo
     */
    getCertificateImage(key, pdfInfo) {
      let imageKey = '';
      if (
        pdfInfo.gpCertificationsImages
        && pdfInfo.gpCertificationsImages.entry
      ) {
        pdfInfo.gpCertificationsImages.entry.forEach((certificate) => {
          if (key === certificate.key) {
            imageKey = `data:image/jpeg;base64,${certificate.value}`;
          }
        });
      }
      return imageKey;
    },
    /**
     * Used to generate Bar Code
     * @param {Array} upcValue
     */
    generatePdfBarcode(upcValue) {
      if (upcValue && (upcValue.length === 12 || upcValue.length === 11)) {
        JsBarcode('.pdf-itf', upcValue, {
          format: 'upc',
        });
        const barcodeImg = document.querySelector('img.pdf-itf');
        this.pdf.addImage(
          barcodeImg.src,
          'JPEG',
          430,
          780,
          150,
          40,
        );
      }
    },
    /**
     * Returns the extension from name
     * @param {string} name
     */
    getExtension(name) {
      let extension = 'jpeg';
      if (name.includes('image/png')) {
        extension = 'png';
      }
      return extension;
    },
    /**
     * Creates Left section of the PDF
     * @param {Boolean} fromPdp
     */
    createLeftSection(fromPdp) {
      this.pdf.setFont(gothicBold, 'bold');
      this.pdf.setDrawColor(0);
      const id = `${(this.pdpProductInfo.code).replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '\\\\$&')}`;
      const source2 = fromPdp ? document.querySelector('.pdf-left-section') : document.getElementById(id);
      const source2Array = [];
      for (let i = 0; i < 3; i += 1) {
        if (source2.children[i]) {
          source2Array[i] = source2.children[i].innerText.replace('(TM)', '™');
        }
      }
      const source3 = fromPdp
        ? document.querySelector('.pdf-features')
        : document.getElementById(id).nextElementSibling.nextElementSibling;
      const source4 = fromPdp
        ? document.querySelector('.pdf-feature-heading')
        : document.getElementById(id).nextElementSibling;
      const marginsForLeftSection = {
        top: this.headingTextBoxHeight + 75,
        bottom: 0,
        left: 25,
        width: 250,
      };
      const renderFeaturesHeading = (logoTop) => {
        this.pdf.setFont(gothicBold, 'bold');
        if (source4 && logoTop < 720) {
          this.pdf.setFontSize(10.5);
          this.pdf.text(marginsForLeftSection.left, logoTop, source4.innerText.trim());
        }
      };
      const renderFeatures = (logoTop) => {
        if (logoTop < 720) {
          this.pdf.setFont(gothicLight, 'normal');
          this.pdf.setFontSize(10);
          const featuresCount = source3.children[0].children.length;
          let yVal = logoTop;
          for (let i = 0; i < featuresCount; i += 1) {
            if (yVal < 720) {
              const featureStr = source3.children[0].children[i].innerText.replace('(TM)', '™');
              const thisFeatureArray = this.pdf.splitTextToSize(featureStr, 250);

              for (let j = 0; j < thisFeatureArray.length; j += 1) {
                const xVal = j === 0 ? marginsForLeftSection.left : marginsForLeftSection.left + 10;
                const featureLine = j === 0 ? `\u2022  ${thisFeatureArray[j]}` : thisFeatureArray[j];
                this.pdf.text(xVal, yVal, featureLine);
                yVal += 12;
              }
            }
          }
        }
      };
      this.pdf.setFont(gothicBold, 'bold');
      this.pdf.setFontSize(10.5);
      // this.pdf.addFont('arial-normal', 'Arial', 'normal');
      let yVal = marginsForLeftSection.top + 20;
      const productTitleArray = this.pdf.splitTextToSize(source2Array[0], 280);
      for (let i = 0; i < productTitleArray.length; i += 1) {
        this.pdf.text(marginsForLeftSection.left, yVal, productTitleArray[i]);
        yVal += 12;
      }
      yVal += 10;
      if (source2Array[1]) {
        this.pdf.text(marginsForLeftSection.left, yVal, source2Array[1].trim());
        yVal += 20;
      }

      if (source2Array[1]) {
        const productDesc = source2Array[2].replace(/<br\s*\/?>/gi, '\n').trim();
        const tmp = document.createElement('div');
        tmp.innerHTML = productDesc;
        const productDescFormatted = tmp.textContent;

        const descSplit = this.pdf.splitTextToSize(productDescFormatted, 320);
        this.pdf.setFont(gothicLight, 'normal');
        this.pdf.setFontSize(10);
        for (let i = 0; i < descSplit.length; i += 1) {
          this.pdf.text(marginsForLeftSection.left, yVal, descSplit[i]);
          yVal += 11;
        }
      }
      let logoTop = yVal;
      let logoLeft = 25;
      if (this.pdpProductInfo.priceText) {
        const text = this.pdpProductInfo.priceText.split('|');
        this.pdf.text(logoLeft, logoTop + 10, text);
        logoTop += 20;
      }
      const certIcons = this.pdpProductInfo.gpCertifications || this.pdfData.gpCertifications;
      if (certIcons) {
        for (let i = 0; i < certIcons.length; i += 1) {
          const image = this.getCertificateImage(
            certIcons[i].id,
            this.pdfInfo,
          );
          if (image) {
            if (i > 0 && !((i) % 7)) {
              logoLeft = 25;
              logoTop += 35;
            }
            this.pdf.addImage(
              image,
              this.getExtension(image),
              logoLeft,
              logoTop,
              30,
              30,
            );
          }
          logoLeft += 40;
        }
        logoTop += 45;
      } else {
        logoTop += 10;
      }
      renderFeaturesHeading(logoTop);
      logoTop += 20;
      renderFeatures(logoTop);

      if (this.pdpProductInfo.upc || this.pdfData.upc) {
        this.generatePdfBarcode(this.pdpProductInfo.upc || this.pdfData.upc);
      }
    },
    /**
     * Renders the image based on param sent as an argument
     * @param {object} param
     */
    renderImage(param) {
      const { id, src } = param;
      const img = id ? document.querySelector(id).src : src;
      this.toDataURL(img, this.updateImageSrc, 'imgBlob');
    },
    /**
     * Creates Right Column of the PDF
     * @param {Boolean} fromPdp
     */
    createRightSection(fromPdp) {
      let top = this.headingTextBoxHeight + 170;
      if (fromPdp) {
        const imgData = this.pdfInfo.encodedImages
        && this.pdfInfo.encodedImages[0]
          ? `data:image/jpeg;base64,${this.pdfInfo.encodedImages[0]}`
          : imgBlob.noImage;
        this.pdf.addImage(
          imgData,
          this.getExtension(imgData),
          410,
          this.headingTextBoxHeight + 90,
          80,
          80,
        );
      } else {
        const imgData = this.pdpProductInfo.encodedImages
        && this.pdpProductInfo.encodedImages[0]
          ? `data:image/jpeg;base64,${this.pdpProductInfo.encodedImages[0]}`
          : imgBlob.noImage;
        this.pdf.addImage(
          imgData,
          this.getExtension(imgData),
          410,
          this.headingTextBoxHeight + 90,
          80,
          80,
        );
      }

      if (
        this.pdpProductInfo.specifications
        && this.pdpProductInfo.specifications.length > 0
      ) {
        for (let i = 0; i < this.pdpProductInfo.specifications.length; i += 1) {
          const section = this.pdpProductInfo.specifications[i].subCategories;
          this.pdf.setDrawColor(0);
          this.pdf.setFontSize(8);
          this.pdf.setFillColor(211, 211, 211);
          top += 5;
          this.pdf.rect(330, top, 250, 16, 'F');
          this.pdf.setTextColor('#222222');
          top += 10;
          this.pdf.setFont(gothicBold, 'bold');
          this.pdf.text(
            340,
            top,
            this.pdpProductInfo.specifications[i].categoryLabel.toUpperCase(),
          );
          top += 4;
          this.pdf.setFont(gothicLight, 'normal');
          for (let j = 0; j < section.length; j += 1) {
            top += 12;
            this.pdf.setFontSize(8);
            this.pdf.text(340, top, section[j].attributeLabel);
            this.pdf.text(470, top, section[j].columnValues[0]);
            if (
              this.pdpProductInfo.specifications[i].categoryLabel
              === generatePdf.productDetails
              && j === section.length - 1
            ) {
              if (
                this.pdpProductInfo.customAttr1Value
                && this.pdpProductInfo.customAttr1Value !== ''
              ) {
                top += 15;
                this.pdf.text(340, top, this.data.customAttr1);
                this.pdf.text(470, top, this.pdpProductInfo.customAttr1Value);
              }
              if (
                this.pdpProductInfo.customAttr2Value
                && this.pdpProductInfo.customAttr2Value !== ''
              ) {
                top += 15;
                this.pdf.text(340, top, this.data.customAttr2);
                this.pdf.text(470, top, this.pdpProductInfo.customAttr2Value);
              }
              if (
                this.pdpProductInfo.customAttr3Value
                && this.pdpProductInfo.customAttr3Value !== ''
              ) {
                top += 15;
                this.pdf.text(340, top, this.data.customAttr3);
                this.pdf.text(470, top, this.pdpProductInfo.customAttr3Value);
              }
            }
          }
        }
      }
      // Render Right side box
      this.pdf.setDrawColor('#D3D3D3');
      this.pdf.rect(330, this.headingTextBoxHeight + 80, 250, 630);
    },
    /**
     * Creates one column PDF format
     * @param {object} pdfInfo
     */
    createOneColumnFormat(pdfInfo) {
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.resetDimensions();
      this.createPDFInstance();
      if (pdfInfo.coverPage) {
        this.renderCoverPage(pdfInfo);
      }
      this.addFormattingOneColumn(pdfInfo);
      this.addContentOneColumn(pdfInfo);
      this.savePdf(pdfInfo);
    },
    /**
     * Creates Header, subheader and footer for One Column PDF format
     * @param {object} pdfInfo
     */
    addFormattingOneColumn(pdfInfo) {
      this.createHeader(pdfInfo);
      if (pdfInfo.onlyOnFirstPage && this.firstPage) {
        this.createSubHeader(pdfInfo);
        this.firstPage = false;
      } else if (!pdfInfo.onlyOnFirstPage) {
        this.createSubHeader(pdfInfo);
      } else {
        this.headingTextBoxHeight = 0;
      }
      this.createFooter(pdfInfo);
    },
    /**
     * Creates content for one column PDF format
     * @param {object} pdfInfo
     */
    addContentOneColumn(pdfInfo) {
      let imgData = '';
      let left = 35;
      let splitTitle = '';
      let categoryHeight = 0;
      let productTop = 100 + this.headingTextBoxHeight;
      let logoTop = 0;
      const logoLeft = 85;
      let productSellingHeight = 0;
      let productCounter = 0;
      let newCategoryPage = false;
      const productSplitSize = 430;
      const productNameSize = 100;
      const minNameHeight = 10;
      const sizeFactor = 40;
      let innerLogoLeft = 0;
      const itemsPerPage = 7;
      this.data.wishlistEntriesGroup.forEach((category) => {
        if (category.value.wishlistEntryGroup.length > 0) {
          if (productCounter !== 0 && productCounter % itemsPerPage === 0) {
            this.pdf.addPage();
            newCategoryPage = true;
            this.addFormattingOneColumn(pdfInfo);
            productTop = 120 + this.headingTextBoxHeight;
            left = 35;
          }
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            categoryHeight = category.value.wishlistEntryGroup[0].product.categories[0].name
              .length < 105
              && category.value.wishlistEntryGroup[0].product.categories[0]
                .description.length < 110
              ? 35
              : Math.ceil(
                (category.value.wishlistEntryGroup[0].product.categories[0]
                  .name.length
                      + category.value.wishlistEntryGroup[0].product.categories[0]
                        .description.length)
                      / 127,
              )
                    * 8
                  + 35;
          } else {
            categoryHeight = 25;
          }
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          ctx.font = '10px Arial';
          const catNameLength = ctx.measureText(category.value.wishlistEntryGroup[0].product.categories[0].name).width;
          this.pdf.setFillColor(211, 211, 211);
          this.pdf.rect(left - 5, productTop - 15, 500, categoryHeight, 'F');
          this.pdf.setFontSize(10);
          this.pdf.setFont(gothicBold, 'bold');
          this.pdf.text(
            (left + (((475 / 2)) - (catNameLength / 2))),
            productTop,
            category.value.wishlistEntryGroup[0].product.categories[0].name,
          );
          this.pdf.setFontSize(8);
          productTop += 10;
          this.pdf.setFont(gothicLight, 'normal');
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            splitTitle = this.pdf.splitTextToSize(
              category.value.wishlistEntryGroup[0].product.categories[0]
                .description,
              productSplitSize,
            );
            this.pdf.text(35, productTop, splitTitle);
          }
          productTop += categoryHeight - 10;
          category.value.wishlistEntryGroup.forEach((product) => {
            imgData = product.product.encodedImages && product.product.encodedImages[0]
              ? `data:image/jpeg;base64,${product.product.encodedImages[0]}`
              : imgBlob.noImage;
            if (productCounter % itemsPerPage === 0 && !newCategoryPage) {
              if (productCounter !== 0) {
                this.pdf.addPage();
                this.addFormattingOneColumn(pdfInfo);
                productTop = 120 + this.headingTextBoxHeight;
                left = 35;
              }
            }
            this.pdf.addImage(
              imgData,
              'JPEG',
              left - 5,
              productTop - 5,
              50,
              50,
            );
            this.pdf.setFont(gothicBold, 'bold');
            this.pdf.text(
              left + 50,
              productTop,
              `Mfg # ${product.product.code}`,
            );
            if (product.product.cmirCode) {
              this.pdf.setTextColor(150);
              this.pdf.text(left + 115, productTop, '|');
              this.pdf.setTextColor(0);
              this.pdf.text(
                left + 130,
                productTop,
                `Cust # ${product.product.cmirCode}`,
              );
            }
            productTop += 15;
            if (
              product.product
              && product.product.priceText
              && product.product.priceText !== ''
            ) {
              const text = product.product.priceText.split('|');
              this.pdf.setFont(gothicBold, 'bold');
              this.pdf.text(
                left + 50,
                productTop + 10,
                text,
              );
              productTop += 20;
            }

            this.pdf.setFont(gothicLight, 'normal');
            if (product.customAttr1Value && this.data.customAttr1 && product.customAttr1Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr1} : ${product.customAttr1Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr2Value && this.data.customAttr2 && product.customAttr2Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr2}  : ${product.customAttr2Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr3Value && this.data.customAttr3 && product.customAttr3Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr3}  : ${product.customAttr3Value}`,
              );
              productTop += 10;
            }
            productTop += 5;
            const specifications = product.product.displayAttributes;
            const { name } = product.product;
            productTop = this.detailedProductName(specifications, name, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor);
            if (
              pdfInfo.productSellingStatement
              && product.product.sellingstmt
            ) {
              splitTitle = this.pdf.splitTextToSize(
                product.product.sellingstmt,
                110,
              );
              this.pdf.text(left + 50, productTop, splitTitle);
              productSellingHeight = product.product.sellingstmt.length < 50
                ? 15
                : product.product.sellingstmt.length / 30 * 12;
              productTop += productSellingHeight;
              logoTop = productTop;
              productTop += 40;
            } else {
              logoTop = productTop;
              productTop += 40;
            }
            if (product.product.gpCertifications) {
              innerLogoLeft = logoLeft;
              for (
                let i = 0;
                i < product.product.gpCertifications.length;
                i += 1
              ) {
                const image = this.getCertificateImage(
                  product.product.gpCertifications[i].id,
                  pdfInfo,
                );
                if (image) {
                  this.pdf.addImage(
                    image,
                    this.getExtension(image),
                    innerLogoLeft,
                    logoTop,
                    15,
                    15,
                  );
                }
                innerLogoLeft += 20;
              }
              logoTop += 50;
            }
            productCounter += 1;
            if (productCounter % itemsPerPage === 0) {
              newCategoryPage = false;
            }
          });
        }
      });
    },
    /**
     * Creates three column PDF format
     * @param {object} pdfInfo
     */
    createThreeColumnFormat(pdfInfo) {
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.resetDimensions();
      this.createPDFInstance();
      if (pdfInfo.coverPage) {
        this.renderCoverPage(pdfInfo);
      }
      this.addFormattingThreeColumn(pdfInfo);
      this.addContentThreeColumn(pdfInfo);
      this.savePdf(pdfInfo);
    },
    /**
     * Creates Header, Footer and Subheader for three column PDF Format
     * @param {object} pdfInfo
     */
    addFormattingThreeColumn(pdfInfo) {
      this.createHeader(pdfInfo);
      if (pdfInfo.onlyOnFirstPage && this.firstPage) {
        this.createSubHeader(pdfInfo);
        this.firstPage = false;
      } else if (!pdfInfo.onlyOnFirstPage) {
        this.createSubHeader(pdfInfo);
      } else {
        this.headingTextBoxHeight = 0;
      }
      this.createFooter(pdfInfo);
      this.pdf.setDrawColor('#D3D3D3');
      this.pdf.setLineWidth(0.1);
      this.pdf.line(205, this.headingTextBoxHeight + 100, 205, 750);
      this.pdf.line(385, this.headingTextBoxHeight + 100, 385, 750);
      this.pdf.rect(
        24,
        this.headingTextBoxHeight + 100,
        540,
        this.headingTextBoxHeight < 50 ? 650 : this.headingTextBoxHeight + 550,
      );
    },
    /**
     * Creates content for three column PDF Format
     * @param {object} pdfInfo
     */
    addContentThreeColumn(pdfInfo) {
      let productTop = 120 + this.headingTextBoxHeight;
      let left = 35;
      let newPage = false;
      let imgData = '';
      let splitTitle = '';
      let categoryHeight = 0;
      let logoTop = 0;
      let logoLeft = 85;
      let productSellingHeight = 0;
      let productCounter = 0;
      let categoryLeft = false;
      let newCategoryPage = false;
      const productSplitSize = 110;
      const productNameSize = 30;
      const minNameHeight = 10;
      const sizeFactor = 30;
      let innerLogoLeft = 0;
      const itemsPerCol = 4;
      this.data.wishlistEntriesGroup.forEach((category) => {
        if (category.value.wishlistEntryGroup.length > 0) {
          if (productCounter !== 0 && productCounter % itemsPerCol === 0) {
            if (productCounter % (itemsPerCol * 3) !== 0) {
              left += 180;
              logoLeft = left + 50;
              productTop = 120 + this.headingTextBoxHeight;
              categoryLeft = true;
            } else {
              this.pdf.addPage();
              newCategoryPage = true;
              this.addFormattingThreeColumn(pdfInfo);
              newPage = true;
              productTop = 120 + this.headingTextBoxHeight;
              left = 35;
              logoLeft = 85;
            }
          }
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            categoryHeight = category.value.wishlistEntryGroup[0].product.categories[0].name
              .length < 38
              && category.value.wishlistEntryGroup[0].product.categories[0]
                .description.length < 40
              ? 35
              : Math.ceil(
                (category.value.wishlistEntryGroup[0].product.categories[0]
                  .name.length
                      + category.value.wishlistEntryGroup[0].product.categories[0]
                        .description.length)
                      / 46,
              )
                    * 8
                  + 35;
          } else {
            categoryHeight = 25;
          }
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          ctx.font = '10px Arial';
          const catNameLength = ctx.measureText(category.value.wishlistEntryGroup[0].product.categories[0].name).width;
          this.pdf.setFillColor(211, 211, 211);
          this.pdf.rect(left - 5, productTop - 15, 170, categoryHeight, 'F');
          let fontSize = 10;
          let fontOffset = 0;
          if (catNameLength > 100) {
            fontSize -= 1;
            fontOffset = 12;
          }
          this.pdf.setFontSize(fontSize);
          this.pdf.setFont(gothicBold, 'bold');
          if (catNameLength > 100) {
            const catName = category.value.wishlistEntryGroup[0].product.categories[0].name;
            const catArray = catName.split(' ');
            const splitIndex = catArray.length / 2;
            const firstLine = catArray.slice(0, splitIndex).join(' ');
            const secondLine = catArray.slice(splitIndex, catArray.length).join(' ');
            this.pdf.text(
              (left + (((145) / 2) - (ctx.measureText(firstLine).width / 2) + fontOffset)),
              productTop - 5,
              firstLine,
            );
            this.pdf.text(
              (left + (((145) / 2) - (ctx.measureText(secondLine).width / 2) + fontOffset)),
              productTop + 5,
              secondLine,
            );
          } else {
            this.pdf.text(
              (left + (((160) / 2) - (catNameLength / 2) + fontOffset)),
              productTop,
              category.value.wishlistEntryGroup[0].product.categories[0].name,
            );
          }

          productTop += 10;
          this.pdf.setFontSize(8);
          this.pdf.setFont(gothicLight, 'normal');
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            splitTitle = this.pdf.splitTextToSize(
              category.value.wishlistEntryGroup[0].product.categories[0]
                .description,
              110,
            );
            this.pdf.text(35, productTop, splitTitle);
          }
          productTop += categoryHeight - 5;
          category.value.wishlistEntryGroup.forEach((product) => {
            imgData = product.product.encodedImages && product.product.encodedImages[0]
              ? `data:image/jpeg;base64,${product.product.encodedImages[0]}`
              : imgBlob.noImage;
            if (productCounter % (itemsPerCol * 3) === 0 && !newCategoryPage) {
              if (productCounter !== 0) {
                this.pdf.addPage();
                this.addFormattingThreeColumn(pdfInfo);
                newPage = true;
                productTop = 120 + this.headingTextBoxHeight;
                left = 35;
                logoLeft = 85;
              }
            }
            if (!productCounter || productCounter % itemsPerCol !== 0 || newPage) {
              newPage = false;
            } else if (categoryLeft) {
              categoryLeft = false;
            } else {
              left += 180;
              logoLeft = left + 50;
              productTop = 120 + this.headingTextBoxHeight;
            }
            this.pdf.addImage(
              imgData,
              'JPEG',
              left - 5,
              productTop - 5,
              50,
              50,
            );
            this.pdf.setFont(gothicBold, 'bold');
            this.pdf.text(
              left + 50,
              productTop,
              `Mfg # ${product.product.code}`,
            );

            if (product.product.cmirCode) {
              this.pdf.text(
                left + 50,
                productTop + 10,
                `Cust # ${product.product.cmirCode}`,
              );
            }
            productTop += 15;

            if (
              product.product
              && product.product.priceText
              && product.product.priceText !== ''
            ) {
              const text = product.product.priceText.split('|');
              this.pdf.setFont(gothicBold, 'bold');
              this.pdf.text(
                left + 50,
                productTop + 10,
                text,
              );
              productTop += 20;
            }
            this.pdf.setFont(gothicLight, 'normal');

            if (product.customAttr1Value && this.data.customAttr1 && product.customAttr1Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr1} : ${product.customAttr1Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr2Value && this.data.customAttr2 && product.customAttr2Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr2}  : ${product.customAttr2Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr3Value && this.data.customAttr3 && product.customAttr3Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr3}  : ${product.customAttr3Value}`,
              );
              productTop += 10;
            }
            productTop += 5;
            const specifications = product.product.displayAttributes;
            const { name } = product.product;
            productTop = this.detailedProductName(specifications, name, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor);
            if (
              pdfInfo.productSellingStatement
              && product.product.sellingstmt
            ) {
              splitTitle = this.pdf.splitTextToSize(
                product.product.sellingstmt,
                110,
              );
              this.pdf.text(left + 50, productTop, splitTitle);
              productSellingHeight = product.product.sellingstmt.length < 30
                ? 10
                : product.product.sellingstmt.length / 30 * 12;
              productTop += productSellingHeight;
            }
            logoTop = productTop - 5;
            if (product.product.gpCertifications) {
              productTop += 30;
              innerLogoLeft = logoLeft;
              for (
                let i = 0;
                i < product.product.gpCertifications.length;
                i += 1
              ) {
                const image = this.getCertificateImage(
                  product.product.gpCertifications[i].id,
                  pdfInfo,
                );
                if (image) {
                  if (i > 0 && !((i) % 6)) {
                    innerLogoLeft = logoLeft;
                    logoTop += 20;
                    productTop += 20;
                  }
                  this.pdf.addImage(
                    image,
                    this.getExtension(image),
                    innerLogoLeft,
                    logoTop,
                    15,
                    15,
                  );
                }
                innerLogoLeft += 20;
              }
              logoTop += 50;
              innerLogoLeft = logoLeft;
            } else {
              productTop += 20;
            }
            const currentIndex = category.value.wishlistEntryGroup.indexOf(product);
            const pTopLimit = currentIndex + 1 === category.value.wishlistEntryGroup.length ? 600 : 640;
            if ((productCounter + 1) % (itemsPerCol) === 0 && productTop <= pTopLimit) {
              productCounter += 0;
            } else {
              productCounter += 1;
            } if (productCounter % (itemsPerCol * 3) === 0) {
              newCategoryPage = false;
            }
          });
        }
      });
    },
    /**
     * PDF gets rendered based on the type of PDF selected
     * @param {object} pdfInfo
     * @param {Array} featureProducts
     */
    createCustomStylePDF(pdfInfo, featureProducts) {
      this.resetDimensions();
      this.createPDFInstance();
      if (pdfInfo.coverPage) {
        this.renderCoverPage(pdfInfo);
      }
      this.uncheckedData = [];
      if (featureProducts.length) {
        featureProducts.forEach((item) => {
          const productCode = item;
          this.data.wishlistEntriesGroup.forEach((category) => {
            category.value.wishlistEntryGroup.forEach((product, index) => {
              if (productCode === product.product.code) {
                this.featuredData.push(product);
                category.value.wishlistEntryGroup.splice(index, 1);
              }
            });
          });
        });
      }
      this.data.wishlistEntriesGroup.forEach((category) => {
        category.value.wishlistEntryGroup.forEach((product) => {
          this.uncheckedData.push(product);
        });
      }); this.customFullPdf(pdfInfo, this.featuredData);
      if (this.uncheckedData.length > 0 && this.maxFullDetail) {
        this.maxFullDetail = false;
        this.pdf.addPage();
        this.showSustainibility = false;
        switch (pdfInfo.formatColumns) {
          case 'Display in one column':
            this.addFormattingOneColumn(pdfInfo);
            this.addContentOneColumn(pdfInfo);
            break;
          case 'Display in two columns':
            this.addFormattingTwoColumn(pdfInfo);
            this.addContentTwoColumn(pdfInfo);
            break;
          case 'Display in three columns':
            this.addFormattingThreeColumn(pdfInfo);
            this.addContentThreeColumn(pdfInfo);
            break;
          case 'Display as full detail':
            this.customFullPdf(pdfInfo, this.uncheckedData);
            this.isFullDetail = true;
            break;
          default:
            this.createOneColumnFormat();
        }
      }
      this.savePdf(pdfInfo);
    },
    /**
     * Renders Full PDF
     * @param {object} pdfInfo
     * @param {Array} data
     */
    customFullPdf(pdfInfo, data) {
      this.pdfInfo = pdfInfo;
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      const productLength = data.length;
      data.forEach((pdpProductInfo, index) => {
        this.pdpProductInfo = pdpProductInfo.product;
        this.pdpProductInfo.customAttr1Value = pdpProductInfo.customAttr1Value;
        this.pdpProductInfo.customAttr2Value = pdpProductInfo.customAttr2Value;
        this.pdpProductInfo.customAttr3Value = pdpProductInfo.customAttr3Value;
        this.createHeader(pdfInfo);
        if (pdfInfo.onlyOnFirstPage && this.firstPage) {
          this.createSubHeader(pdfInfo);
          this.firstPage = false;
        } else if (!pdfInfo.onlyOnFirstPage) {
          this.createSubHeader(pdfInfo);
        } else {
          this.headingTextBoxHeight = 0;
        }
        this.createFooter(pdfInfo);
        this.createLeftSection();
        this.createRightSection();
        if (productLength === index + 1) {
          this.maxFullDetail = true;
        } else {
          this.pdf.addPage();
        }
      });
    },
    /**
     * Creates two column PDF format for Email
     * @param {object} pdfInfo
     */
    createTwoColumnFormatShare(pdfInfo) {
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.resetDimensions();
      this.createPDFInstance();
      this.addFormattingTwoColumn(pdfInfo);
      this.addContentTwoColumn(pdfInfo);
      return btoa(this.pdf.output());
    },
    /**
     * Creates Two Column PDF Format
     * @param {object} pdfInfo
     */
    createTwoColumnFormat(pdfInfo) {
      this.resetDimensions();
      this.createPDFInstance();
      if (pdfInfo.coverPage) {
        this.renderCoverPage(pdfInfo);
      }
      this.addFormattingTwoColumn(pdfInfo);
      this.addContentTwoColumn(pdfInfo);
      this.savePdf(pdfInfo);
    },
    /**
     * Creates Header, Subheader and footer for Two Columns PDF format
     * @param {object} pdfInfo
     */
    addFormattingTwoColumn(pdfInfo) {
      this.createHeader(pdfInfo);
      if (pdfInfo.onlyOnFirstPage && this.firstPage) {
        this.createSubHeader(pdfInfo);
        this.firstPage = false;
      } else if (!pdfInfo.onlyOnFirstPage) {
        this.createSubHeader(pdfInfo);
      } else {
        this.headingTextBoxHeight = 0;
      }
      this.createFooter(pdfInfo);
      this.pdf.setDrawColor('#D3D3D3');
      this.pdf.setLineWidth(0.1);
      this.pdf.line(290, this.headingTextBoxHeight + 100, 294, 750);
      this.pdf.rect(
        24,
        this.headingTextBoxHeight + 100,
        540,
        this.headingTextBoxHeight < 50 ? 650 : this.headingTextBoxHeight + 550,
      );
    },
    /**
     * Renders content for Two Column PDF Format
     * @param {object} pdfInfo
     */
    addContentTwoColumn(pdfInfo) {
      let productTop = 120 + this.headingTextBoxHeight;
      let left = 35;
      let newPage = false;
      let imgData = '';
      let splitTitle = '';
      let categoryHeight = 0;
      let logoLeft = 85;
      let logoTop = 0;
      let productSellingHeight = 0;
      let productCounter = 0;
      let categoryLeft = false;
      let newCategoryPage = false;
      const productSplitSize = 200;
      const productNameSize = 50;
      const minNameHeight = 10;
      const sizeFactor = 30;
      let innerLogoLeft = 0;
      const itemsPerCol = 6;
      if (this.pdfData) {
        this.data = JSON.parse(JSON.stringify(this.pdfData));
      }
      this.data.wishlistEntriesGroup.forEach((category) => {
        if (category.value.wishlistEntryGroup.length > 0) {
          if (productCounter !== 0 && productCounter % itemsPerCol === 0) {
            if (productCounter % (itemsPerCol * 2) !== 0) {
              left += 269;
              logoLeft = left + 50;
              productTop = 120 + this.headingTextBoxHeight;
              categoryLeft = true;
            } else {
              this.pdf.addPage();
              newCategoryPage = true;
              this.addFormattingTwoColumn(pdfInfo);
              newPage = true;
              productTop = 120 + this.headingTextBoxHeight;
              left = 35;
              logoLeft = 85;
            }
          }
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            categoryHeight = category.value.wishlistEntryGroup[0].product.categories[0].name
              .length < 58
              && category.value.wishlistEntryGroup[0].product.categories[0]
                .description.length < 65
              ? 35
              : Math.ceil(
                (category.value.wishlistEntryGroup[0].product.categories[0]
                  .name.length
                      + category.value.wishlistEntryGroup[0].product.categories[0]
                        .description.length)
                      / 64,
              )
                    * 8
                  + 35;
          } else {
            categoryHeight = 25;
          }
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          ctx.font = '11px Arial';
          const catNameLength = ctx.measureText(category.value.wishlistEntryGroup[0].product.categories[0].name).width;
          this.pdf.setFillColor(211, 211, 211);
          this.pdf.rect(left - 5, productTop - 15, 255, categoryHeight, 'F');
          let fontSize = 10;
          let fontOffset = 0;
          if (catNameLength > 270) {
            fontSize -= 1;
            fontOffset = 12;
          }
          this.pdf.setFontSize(fontSize);
          this.pdf.setFont(gothicBold, 'bold');
          this.pdf.text(
            (left + (((255) / 2) - (catNameLength / 2) + fontOffset)),
            productTop,
            category.value.wishlistEntryGroup[0].product.categories[0].name,
          );
          productTop += 10;
          this.pdf.setFontSize(8);
          this.pdf.setFont(gothicLight, 'normal');
          if (
            pdfInfo.categoryDescription
            && category.value.wishlistEntryGroup[0].product.categories[0]
              .description
          ) {
            splitTitle = this.pdf.splitTextToSize(
              category.value.wishlistEntryGroup[0].product.categories[0]
                .description,
              200,
            );
            this.pdf.text(35, productTop, splitTitle);
          }
          productTop += categoryHeight - 5;
          category.value.wishlistEntryGroup.forEach((product) => {
            imgData = product.product.encodedImages && product.product.encodedImages[0]
              ? `data:image/jpeg;base64,${product.product.encodedImages[0]}`
              : imgBlob.noImage;

            if (productCounter % (itemsPerCol * 2) === 0 && !newCategoryPage) {
              if (productCounter !== 0) {
                this.pdf.addPage();
                this.addFormattingTwoColumn(pdfInfo);
                newPage = true;
                productTop = 120 + this.headingTextBoxHeight;
                left = 35;
                logoLeft = 85;
              }
            }
            if (!productCounter || productCounter % itemsPerCol !== 0 || newPage) {
              newPage = false;
            } else if (categoryLeft) {
              categoryLeft = false;
            } else {
              left += 269;
              logoLeft = left + 50;
              productTop = 120 + this.headingTextBoxHeight;
            }
            this.pdf.addImage(imgData, 'JPEG', left - 5, productTop, 50, 50);
            this.pdf.setFont(gothicBold, 'bold');
            this.pdf.text(
              left + 50,
              productTop,
              `Mfg # ${product.product.code}`,
            );
            if (product.product.cmirCode) {
              this.pdf.setTextColor(150);
              this.pdf.text(left + 115, productTop, '|');
              this.pdf.setTextColor(0);
              this.pdf.text(
                left + 130,
                productTop,
                `Cust # ${product.product.cmirCode}`,
              );
            }
            productTop += 15;

            if (
              product.product
              && product.product.priceText
              && product.product.priceText !== ''
            ) {
              const text = product.product.priceText.split('|');
              this.pdf.setFont(gothicBold, 'bold');
              this.pdf.text(
                left + 50,
                productTop + 10,
                text,
              );
              productTop += 20;
            }
            this.pdf.setFont(gothicLight, 'normal');

            if (product.customAttr1Value && this.data.customAttr1 && product.customAttr1Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr1} : ${product.customAttr1Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr2Value && this.data.customAttr2 && product.customAttr2Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr2}  : ${product.customAttr2Value}`,
              );
              productTop += 10;
            }
            if (product.customAttr3Value && this.data.customAttr3 && product.customAttr3Value !== '') {
              this.pdf.text(
                left + 50,
                productTop,
                `${this.data.customAttr3}  : ${product.customAttr3Value}`,
              );
              productTop += 10;
            }
            productTop += 5;
            const specifications = product.product.displayAttributes;
            const { name } = product.product;
            productTop = this.detailedProductName(specifications, name, left, productTop, productSplitSize, productNameSize, minNameHeight, sizeFactor);
            if (
              pdfInfo.productSellingStatement
              && product.product.sellingstmt
            ) {
              splitTitle = this.pdf.splitTextToSize(
                product.product.sellingstmt,
                110,
              );
              this.pdf.text(left + 50, productTop, splitTitle);
              productSellingHeight = product.product.sellingstmt.length < 50
                ? 15
                : product.product.sellingstmt.length / 30 * 12;
              productTop += productSellingHeight;
              logoTop = productTop;
            } else {
              logoTop = productTop;
            }
            if (product.product.gpCertifications) {
              productTop += 35;
              innerLogoLeft = logoLeft;
              for (
                let i = 0;
                i < product.product.gpCertifications.length;
                i += 1
              ) {
                const image = this.getCertificateImage(
                  product.product.gpCertifications[i].id,
                  pdfInfo,
                );
                if (image) {
                  if (i > 0 && !((i) % 10)) {
                    innerLogoLeft = logoLeft;
                    logoTop += 20;
                    productTop += 20;
                  }
                  this.pdf.addImage(
                    image,
                    this.getExtension(image),
                    innerLogoLeft,
                    logoTop,
                    15,
                    15,
                  );
                }
                innerLogoLeft += 20;
              }
              logoTop += 50;
              innerLogoLeft = logoLeft;
            } else if (product.product.name.length <= 80) {
              productTop += 35;
            } else {
              productTop += 20;
            }
            const currentIndex = category.value.wishlistEntryGroup.indexOf(product);
            const pTopLimit = currentIndex + 1 === category.value.wishlistEntryGroup.length ? 650 : 670;
            if ((productCounter + 1) % (itemsPerCol) === 0 && productTop <= pTopLimit) {
              productCounter += 0;
            } else {
              productCounter += 1;
            }
            if (productCounter % (itemsPerCol * 2) === 0) {
              newCategoryPage = false;
            }
          });
        }
      });
    },
  },
};
