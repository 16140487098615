var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-wide-banner-carousel row",
      class: _vm.componentTheme
    },
    [
      _c("lazy-component", { on: { show: _vm.onLazyLoad } }),
      _vm._v(" "),
      _vm.carouselData.gpRotatingData.length > 0
        ? _c(
            "div",
            { staticClass: "slides-carousel" },
            [
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: "swiper-button-prev icon-chevron-left",
                    class: { "swiper-button-disabled": _vm.firstSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronLeftTitle,
                      disabled: _vm.firstSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slidePrev()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "swiper",
                { ref: "swiper", attrs: { options: _vm.swiperOptions } },
                _vm._l(_vm.carouselData.gpRotatingData, function(slide, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c(
                      "div",
                      {
                        ref: "slide" + index,
                        refInFor: true,
                        staticClass: "slide"
                      },
                      [
                        _c("div", { staticClass: "text-section" }, [
                          slide.headingText
                            ? _c("div", {
                                ref: "header" + index,
                                refInFor: true,
                                staticClass: "header medium-font",
                                domProps: {
                                  innerHTML: _vm._s(slide.headingText)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          slide.subHeadingText
                            ? _c("div", {
                                ref: "description" + index,
                                refInFor: true,
                                staticClass: "description",
                                domProps: {
                                  innerHTML: _vm._s(slide.subHeadingText)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          slide.linkTo &&
                          slide.ctaText &&
                          slide.componetType !== "videoWrapper"
                            ? _c(
                                "a",
                                {
                                  ref: "link" + index,
                                  refInFor: true,
                                  attrs: { href: slide.linkTo }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      ref: "button" + index,
                                      refInFor: true,
                                      staticClass: "form-blue-button bold-font",
                                      class: slide.ctaStyle,
                                      attrs: {
                                        role: "presentation",
                                        tabindex: "-1"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(slide.ctaText) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          slide.videoSrc &&
                          slide.ctaText &&
                          slide.componetType === "videoWrapper"
                            ? _c(
                                "a",
                                {
                                  attrs: { role: "presentation" },
                                  on: {
                                    click: function($event) {
                                      _vm.openModal(slide.videoSrc)
                                    },
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter-space",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.openModal(slide.videoSrc)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      ref: "button" + index,
                                      refInFor: true,
                                      staticClass: "form-blue-button",
                                      class: slide.ctaStyle,
                                      attrs: { role: "button" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                ►\n                "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(slide.ctaText) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                })
              ),
              _vm._v(" "),
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: "swiper-button-next icon-chevron-right",
                    class: { "swiper-button-disabled": _vm.lastSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronRightTitle,
                      disabled: _vm.lastSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slideNext()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35ecae30", { render: render, staticRenderFns: staticRenderFns })
  }
}