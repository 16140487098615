var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-gp-image-text", attrs: { role: "listitem" } },
    [
      _c(
        "div",
        { staticClass: "image-container" },
        [
          _vm.uiuxImageTileData.imageLink
            ? [
                _c("img", {
                  class: { "is-link": _vm.uiuxImageTileData.imageLink },
                  attrs: {
                    src: _vm.uiuxImageTileData.imageSrcD,
                    alt: _vm.uiuxImageTileData.imageSrcAltTextD
                  },
                  on: {
                    click: function($event) {
                      _vm.goToLink(_vm.uiuxImageTileData.imageLink)
                    }
                  }
                })
              ]
            : [
                _c("img", {
                  attrs: {
                    src: _vm.uiuxImageTileData.imageSrcD,
                    role: "presentation"
                  }
                })
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.uiuxTextTileData.header
        ? _c("div", {
            ref: "category",
            staticClass: "category",
            domProps: { innerHTML: _vm._s(_vm.uiuxTextTileData.header) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextTileData.superHeaderBody
        ? _c("div", {
            ref: "header",
            staticClass: "header",
            domProps: {
              innerHTML: _vm._s(_vm.uiuxTextTileData.superHeaderBody)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextTileData.text
        ? _c("div", {
            ref: "description",
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.uiuxTextTileData.text) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uiuxTextTileData.ctaLink && _vm.uiuxTextTileData.ctaText
        ? _c(
            "a",
            { ref: "link", attrs: { href: _vm.uiuxTextTileData.ctaLink } },
            [
              _c(
                "button",
                {
                  staticClass: "form-blue-button",
                  class: this.uiuxTextTileData.ctaStyle,
                  attrs: { role: "presentation", tabindex: "-1" }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.uiuxTextTileData.ctaText) +
                      "\n        "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a081862", { render: render, staticRenderFns: staticRenderFns })
  }
}