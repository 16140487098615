var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-browse-product-tile d-flex",
      attrs: { role: "listitem" }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tile-body d-flex flex-column" },
        [
          _vm.showCompareOption &&
          _vm.globals.siteConfig.isProductCompareEnabled
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  ref: _vm.product.code + "-checkbox",
                  staticClass: "color-secondary-light-grey",
                  attrs: {
                    type: "checkbox",
                    id: "checkbox" + _vm.product.code,
                    disabled: _vm.isCheckboxDisabled
                  },
                  domProps: { checked: _vm.product.checked },
                  on: {
                    click: function($event) {
                      _vm.checkBoxClicked($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: { "disable-compare": _vm.isCheckboxDisabled },
                    attrs: { for: "checkbox" + _vm.product.code }
                  },
                  [_vm._v(_vm._s(_vm.i18n.label.compare))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSelectOption && _vm.globals.siteConfig.isProductCompareEnabled
            ? _c("div", { staticClass: "form-group refill" }, [
                _c("input", {
                  ref: _vm.product.code + "-checkbox",
                  staticClass: "color-secondary-light-grey",
                  attrs: {
                    type: "checkbox",
                    id: "checkbox" + _vm.product.code,
                    disabled: !(
                      (_vm.product.materialStatus ===
                        _vm.ProductAvailability.OBSOLETE &&
                        _vm.product.stock.stockLevelStatus ===
                          _vm.ProductAvailability.IN_STOCK) ||
                      (_vm.product.materialStatus ===
                        _vm.MaterialStatus.ACTIVE_PRODUCT &&
                        (_vm.product.stock.stockLevelStatus ===
                          _vm.ProductAvailability.IN_STOCK ||
                          _vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.LOW_STOCK))
                    )
                  },
                  domProps: { checked: _vm.product.checked },
                  on: { click: _vm.selectCheckBoxClicked }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: {
                      "disable-compare": !(
                        (_vm.product.materialStatus ===
                          _vm.ProductAvailability.OBSOLETE &&
                          _vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.IN_STOCK) ||
                        (_vm.product.materialStatus ===
                          _vm.MaterialStatus.ACTIVE_PRODUCT &&
                          (_vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.IN_STOCK ||
                            _vm.product.stock.stockLevelStatus ===
                              _vm.ProductAvailability.LOW_STOCK))
                      )
                    },
                    attrs: { for: "checkbox" + _vm.product.code }
                  },
                  [_vm._v(_vm._s(_vm.i18n.label.select))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showRemoveOption
            ? _c(
                "div",
                { staticClass: "remove-product-option d-flex align-center" },
                [
                  _c("span", {
                    staticClass: "icon-x-circle pr-xs-2",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconXCircleTitle,
                      title: _vm.i18n.iconXCircleTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.removeComparePageProduct($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.removeComparePageProduct($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "remove-text" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.i18n.label.remove) + "\n      "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "lazy-container",
                  rawName: "v-lazy-container",
                  value: { selector: "img" },
                  expression: "{ selector: 'img' }"
                }
              ],
              staticClass: "list-left"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "select-container form-group mt-xs-1 ml-xs-2 tile-image"
                },
                [
                  _vm.isBulkEnabled && !_vm.product.hasVariant
                    ? _c("span", [
                        _c("input", {
                          ref: _vm.product.code + "-checkbox",
                          staticClass: "color-secondary-light-grey",
                          attrs: {
                            type: "checkbox",
                            id: "bulkCheckbox" + _vm.product.code
                          },
                          domProps: { checked: _vm.product.isBulk },
                          on: { click: _vm.selectCheckBoxClicked }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "bulkCheckbox" + _vm.product.code } },
                          [
                            _c("span", { staticClass: "select-text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.i18n.label.select) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isFavoriteVisible && !_vm.isBundlesTile
                    ? _c("span", {
                        staticClass:
                          "mb-xs-3 icon-add-to-list tile-icon grid-view tile-image",
                        attrs: {
                          role: "button",
                          tabindex: "0",
                          title: _vm.i18n.iconAddToListTitle
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.handleSelectList($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.handleSelectList($event)
                          }
                        }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tile-image" }, [
                _vm.isBundlesTile
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "form-group mt-xs-1 ml-xs-2 bundle-form-group"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-container",
                            class: { disabled: _vm.isBundlesCheckboxDisabled }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isProductSelected,
                                  expression: "isProductSelected"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.isBundlesCheckboxDisabled,
                                "aria-label": _vm.i18n.label.select
                              },
                              domProps: {
                                checked: Array.isArray(_vm.isProductSelected)
                                  ? _vm._i(_vm.isProductSelected, null) > -1
                                  : _vm.isProductSelected
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.isProductSelected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.isProductSelected = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.isProductSelected = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.isProductSelected = $$c
                                    }
                                  },
                                  _vm.handleProductSelection
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.images
                  ? _c(
                      "a",
                      {
                        staticClass: "image-container justify-content-center",
                        attrs: {
                          tabindex: "0",
                          "aria-label": _vm.product.name
                            ? _vm.product.name
                            : _vm.i18n.noProductName,
                          href: _vm.pdpContextPath + _vm.product.url,
                          title: _vm.product.images[0].altText
                        },
                        on: { click: _vm.selectedItem }
                      },
                      [
                        _vm.product.images &&
                        _vm.product.images[0] &&
                        _vm.product.images[0].url
                          ? _c("img", {
                              attrs: {
                                "data-src": _vm.globals.getThumbnailImageUrl(
                                  _vm.product.images
                                ),
                                "data-loading":
                                  _vm.globals.assetsPath + "images/spinner.gif",
                                alt: _vm.product.name
                                  ? _vm.product.name
                                  : _vm.i18n.noProductName
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.product.images
                          ? _c("img", {
                              attrs: {
                                "data-src":
                                  _vm.globals.assetsPath +
                                  "images/no_image.svg",
                                alt: _vm.product.name
                                  ? _vm.product.name
                                  : _vm.i18n.noProductName,
                                "data-loading":
                                  _vm.globals.assetsPath + "images/spinner.gif"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.promoText
                  ? _c("div", { staticClass: "info-text promo-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.product.promoText) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.materialStatus === "COMING_SOON"
                  ? _c("div", { staticClass: "info-text coming-soon" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.comingSoonBanner) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.promoText
                  ? _c("div", { staticClass: "sr-only" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.product.promoText) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.materialStatus === "COMING_SOON"
                  ? _c("div", { staticClass: "sr-only" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.comingSoonBanner) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFavoriteVisible
                  ? _c(
                      "div",
                      {
                        staticClass: "tile-icon fav-container",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleFav($event)
                          }
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "favorite tile-button",
                            class: { active: _vm.isActive },
                            attrs: {
                              "aria-label": _vm.isActive
                                ? _vm.i18n.iconSavedToFavoriteTitle
                                : _vm.i18n.iconSaveToFavoriteTitle
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "heart",
                                attrs: {
                                  version: "1.1",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                  viewBox: "0 0 25 25",
                                  preserveAspectRatio: "none",
                                  width: "20",
                                  height: "20"
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Page-1",
                                      stroke: "none",
                                      "stroke-width": "2",
                                      fill: "none",
                                      "fill-rule": "evenodd"
                                    }
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "heart-outline",
                                          transform:
                                            "translate(1.000000, 0.000000),scale(0.75, 0.8)",
                                          stroke: "#999"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            id: "fill",
                                            d:
                                              "M22.3634,0.9763 C19.9054,0.9763 17.1654,1.9943 15.9764,5.2263 C15.8984,5.4373 15.5944,5.4293 15.5214,5.2163 C14.4144,1.9913 11.5714,0.9763 9.1154,0.9763 C4.4974,0.9763 0.7394,5.0363 0.7394,10.0263 C0.7394,12.3713 1.5684,14.5963 3.0754,16.2903 L15.0314,28.2673 C15.4224,28.6583 16.0564,28.6593 16.4484,28.2673 L28.1794,16.5343 C29.8234,14.8303 30.7394,12.5113 30.7394,10.0263 C30.7394,5.0363 26.9814,0.9763 22.3634,0.9763",
                                            fill: "#ffffff"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-view-right" },
                [
                  _vm.showProductIndicators &&
                  _vm.globals.siteConfig.showProductIndicators &&
                  !_vm.product.hasVariant
                    ? _c(
                        "div",
                        { staticClass: "product-indicators-container" },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "px-xs-0 pt-xs-2 product-indicators"
                            },
                            [
                              _vm.product.isCertified
                                ? _c("li", { staticClass: "pr-sm-2" }, [
                                    _c("span", {
                                      staticClass: "icon-certification",
                                      attrs: {
                                        title: _vm.iconCertificationTitle,
                                        "aria-label": "certification",
                                        tabindex: "0",
                                        role: "button"
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.isDropShipEligible
                                ? _c("li", { staticClass: "pr-sm-2" }, [
                                    _c("span", {
                                      staticClass: "icon-shipping",
                                      attrs: {
                                        title: _vm.iconShippingTitle,
                                        "aria-label": "shipping",
                                        tabindex: "0",
                                        role: "button"
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.isOnlineOnly
                                ? _c("li", { staticClass: "pr-sm-2" }, [
                                    _c("span", {
                                      staticClass: "icon-online",
                                      attrs: {
                                        title: _vm.iconOnlineTitle,
                                        "aria-label": "online",
                                        tabindex: "0",
                                        role: "button"
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.isSubscribable
                                ? _c("li", { staticClass: "pr-sm-2" }, [
                                    _c("span", {
                                      staticClass: "icon-subscription",
                                      attrs: {
                                        title: _vm.iconSubscriptionTitle,
                                        "aria-label": "subscription",
                                        tabindex: "0",
                                        role: "button"
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.isSeasonal
                                ? _c("li", { staticClass: "pr-sm-2" }, [
                                    _c("span", {
                                      staticClass: "icon-seasonal",
                                      attrs: {
                                        title: _vm.iconSeasonalTitle,
                                        "aria-label": "seasonal",
                                        tabindex: "0",
                                        role: "button"
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product.name
                    ? [
                        _vm.isTruncatedName
                          ? _c(
                              "a",
                              {
                                staticClass: "product-name my-sm-0",
                                class: { "pt-md-2": !_vm.product.subbrand },
                                attrs: {
                                  href: _vm.pdpContextPath + _vm.product.url,
                                  "data-toggle": "tooltip",
                                  title: _vm.getProductName(_vm.product.name)
                                },
                                on: { click: _vm.selectedItem }
                              },
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.formatProductName(
                                        _vm.product.name,
                                        _vm.characterLimit
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isTruncatedName
                          ? _c(
                              "a",
                              {
                                staticClass: "product-name my-sm-0",
                                class: { "pt-md-2": !_vm.product.subbrand },
                                attrs: {
                                  href: _vm.pdpContextPath + _vm.product.url
                                },
                                on: { click: _vm.selectedItem }
                              },
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.formatProductName(
                                        _vm.product.name,
                                        _vm.characterLimit
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bottom-section" },
                    [
                      _vm.globals.siteConfig.showProductStatus &&
                      !_vm.product.hasVariant
                        ? [
                            _vm.globals.siteConfig.isProductIdEnabled
                              ? _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.i18n.label.productCodeA11y) +
                                      " " +
                                      _vm._s(_vm.product.code) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.globals.siteConfig.isProductIdEnabled
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "product-id pt-xs-2 m-xs-0 pb-xs-2",
                                    attrs: {
                                      "aria-hidden":
                                        _vm.globals.siteConfig
                                          .isProductIdEnabled
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.i18n.label.productCode) +
                                        " " +
                                        _vm._s(_vm.product.code) +
                                        "   \n              "
                                    ),
                                    _vm.globals.siteConfig
                                      .enableDisplayAttributes &&
                                    _vm.showDisplayAttributes &&
                                    _vm.product.displayAttributes
                                      ? _c("span", {
                                          staticClass: "display-attributes",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.displayMaxCharacters(
                                                _vm.product.displayAttributes
                                              )
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showCertifications &&
                            _vm.product.gpCertifications &&
                            _vm.product.gpCertifications.length > 0
                              ? _c(
                                  "ul",
                                  {
                                    staticClass: "certifications",
                                    attrs: { role: "list" }
                                  },
                                  [
                                    _vm._l(
                                      _vm.product.gpCertifications,
                                      function(certification) {
                                        return [
                                          certification.url
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass: "certification",
                                                  attrs: { role: "listitem" }
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: certification.url,
                                                        target: "_blank",
                                                        title:
                                                          certification.description
                                                      }
                                                    },
                                                    [
                                                      certification.id
                                                        ? _c("img", {
                                                            staticClass:
                                                              "imgBlock",
                                                            attrs: {
                                                              "data-src":
                                                                certification.gpcertificationsImage,
                                                              "data-loading":
                                                                _vm.globals
                                                                  .assetsPath +
                                                                "images/spinner.gif",
                                                              alt:
                                                                certification.id
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "li",
                                                {
                                                  staticClass: "certification",
                                                  attrs: { role: "listitem" }
                                                },
                                                [
                                                  certification.id
                                                    ? _c("img", {
                                                        staticClass: "imgBlock",
                                                        attrs: {
                                                          title:
                                                            certification.description,
                                                          "data-src":
                                                            certification.gpcertificationsImage,
                                                          alt: certification.id,
                                                          "data-loading":
                                                            _vm.globals
                                                              .assetsPath +
                                                            "images/spinner.gif"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.globals.siteConfig.isProductPriceEnabled &&
                      !_vm.product.hasVariant &&
                      !_vm.product.notPurchasable
                        ? _c(
                            "div",
                            { staticClass: "my-sm-0 prices list-view-price" },
                            [
                              _vm.product.price
                                ? _c("p", { staticClass: "product-price" }, [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.i18n.label.a11yCurrentPrice
                                          ) +
                                          "\n              "
                                      )
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.product.price.formattedValue
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.weblistPrice &&
                              _vm.globals.siteConfig.isSlashedPriceEnabled
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "product-price product-slashed-price"
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.i18n.label.a11ySlashedPrice
                                            ) +
                                            "\n              "
                                        )
                                      ]),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.product.weblistPrice
                                              .formattedValue
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.globals.siteConfig.isProductPriceEnabled &&
                      _vm.product.hasVariant &&
                      !_vm.product.notPurchasable
                        ? _c("div", [
                            _vm.product.price || _vm.product.priceRange
                              ? _c("p", { staticClass: "product-price" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.getVariantsPrice(_vm.product)
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isBazaarVoice === "true"
                        ? _c("div", [
                            _vm.product.bvAverageRating
                              ? _c(
                                  "div",
                                  { staticClass: "star-rating" },
                                  [
                                    _c("vx-star-rating", {
                                      attrs: {
                                        "product-rating":
                                          _vm.product.bvAverageRating,
                                        "product-reviews":
                                          _vm.product.bvNumberOfReviews,
                                        i18n: _vm.i18n,
                                        "show-rating": _vm.showRating,
                                        "show-reviews": _vm.showReviews,
                                        "number-of-stars": 5
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.product.materialStatus ===
                        _vm.ProductAvailability.OBSOLETE &&
                      _vm.product.replacementProductCode &&
                      _vm.product.replacementProductCode !== ""
                        ? _c(
                            "p",
                            { staticClass: "error-text m-xs-0 pb-xs-2" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.pdpContextPath +
                                      "/p/" +
                                      _vm.product.replacementProductCode
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      "\n              " +
                                      _vm._s(_vm.i18n.replacedBy) +
                                      " " +
                                      _vm._s(
                                        _vm.product.replacementProductCode
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.product.materialStatus ===
                        _vm.ProductAvailability.OBSOLETE &&
                      !_vm.product.replacementProductCode
                        ? _c(
                            "p",
                            { staticClass: "error-text m-xs-0 pb-xs-2" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.discontinued) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.product.materialStatus ===
                      _vm.ProductAvailability.COMING_SOON
                        ? _c(
                            "p",
                            {
                              staticClass: "product-stock-status m-xs-0 pb-xs-2"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.availableSoon) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isRefill
                        ? [
                            _c("div", { staticClass: "quantity-label" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.i18n.label.quantity) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("vx-stepper-control", {
                              attrs: {
                                "min-value": _vm.product.minOrderQuantity || 0,
                                maxValue: _vm.product.maxOrderableQuantity,
                                value: _vm.refillQuantity
                              },
                              on: {
                                currentCount: function($event) {
                                  _vm.getQuantity($event)
                                }
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              ),
              _vm._v(" "),
              !_vm.isFavoriteVisible && !_vm.isBundlesTile
                ? _c("span", {
                    staticClass: "mb-xs-3 icon-add-to-list tile-icon list-view",
                    attrs: {
                      tabindex: "0",
                      role: "button",
                      title: _vm.i18n.iconAddToListTitle,
                      "aria-label": _vm.i18n.iconAddToListTitle
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.handleSelectList($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.handleSelectList($event)
                      }
                    }
                  })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottomLinks" },
            [
              _c("div", { staticClass: "list-view-links" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        _vm.pdpContextPath + _vm.product.url + "#specifications"
                    }
                  },
                  [
                    _c("span", { staticClass: "icon-search" }),
                    _vm._v("\n          Specifications\n        ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.pdpContextPath + _vm.product.url + "#related"
                    }
                  },
                  [
                    _c("span", { staticClass: "icon-file" }),
                    _vm._v("\n          Related Items\n        ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.pdpContextPath + _vm.product.url + "#assets"
                    }
                  },
                  [
                    _c("span", { staticClass: "icon-download" }),
                    _vm._v("\n          Download Assets\n        ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        _vm.pdpContextPath +
                        "/find-a-distributor/pdp?productCode=" +
                        _vm.product.code
                    }
                  },
                  [
                    _c("span", { staticClass: "icon-online" }),
                    _vm._v("\n          Find Distributor\n        ")
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.globals.siteConfig.addToCartEnabled && !_vm.hideButtonBlock
                ? [
                    !_vm.isRefill
                      ? _c(
                          "div",
                          { staticClass: "bottom-buttons-container list-view" },
                          [
                            _vm.product.hasVariant
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary tile-button",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.navigateToProduct(_vm.product.url)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.i18n.label.chooseOption) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : (_vm.product.stock &&
                                  _vm.product.stock.stockLevelStatus ===
                                    _vm.ProductAvailability.OUT_OF_STOCK &&
                                    ((_vm.product.notPurchasable &&
                                      (_vm.product.materialStatus ===
                                        _vm.ProductAvailability.COMING_SOON ||
                                        _vm.product.materialStatus ===
                                          _vm.ProductAvailability.ACTIVE)) ||
                                      !_vm.product.notPurchasable)) ||
                                (_vm.product.notPurchasable &&
                                  _vm.product.materialStatus ===
                                    _vm.ProductAvailability.ACTIVE &&
                                  _vm.product.stock.stockLevelStatus ===
                                    _vm.ProductAvailability.IN_STOCK)
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-tertiary tile-button",
                                    attrs: { type: "button", tabindex: "0" },
                                    on: {
                                      click: function($event) {
                                        _vm.handleNotifyMe($event)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.label.notifyMe))]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary add-to-cart-btn tile-button",
                                    class: {
                                      disabled:
                                        (_vm.product.materialStatus ===
                                          _vm.ProductAvailability.OBSOLETE ||
                                          _vm.product.materialStatus ===
                                            _vm.ProductAvailability
                                              .PREDISCONTINUED) &&
                                        _vm.product.notPurchasable
                                    },
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        (_vm.product.materialStatus ===
                                          _vm.ProductAvailability.OBSOLETE ||
                                          _vm.product.materialStatus ===
                                            _vm.ProductAvailability
                                              .PREDISCONTINUED) &&
                                        _vm.product.notPurchasable,
                                      "aria-label": _vm.i18n.label.addToCart
                                    },
                                    on: { click: _vm.addToCart }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.label.addToCart))]
                                )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.globals.siteConfig.isProductPriceEnabled &&
          !_vm.product.hasVariant &&
          !_vm.product.notPurchasable
            ? _c("div", { staticClass: "my-sm-0 prices" }, [
                _vm.product.price
                  ? _c("p", { staticClass: "product-price" }, [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.label.a11yCurrentPrice) +
                            "\n        "
                        )
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.product.price.formattedValue) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.weblistPrice &&
                _vm.globals.siteConfig.isSlashedPriceEnabled
                  ? _c(
                      "p",
                      { staticClass: "product-price product-slashed-price" },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.label.a11ySlashedPrice) +
                              "\n        "
                          )
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.product.weblistPrice.formattedValue) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.isProductPriceEnabled &&
          _vm.product.hasVariant &&
          !_vm.product.notPurchasable
            ? _c("div", [
                _vm.product.price || _vm.product.priceRange
                  ? _c("p", { staticClass: "product-price" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getVariantsPrice(_vm.product)) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.addToCartEnabled && !_vm.hideButtonBlock
            ? [
                !_vm.isRefill
                  ? _c(
                      "div",
                      { staticClass: "bottom-buttons-container grid-view" },
                      [
                        _vm.product.hasVariant
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary tile-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.navigateToProduct(_vm.product.url)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.navigateToProduct(_vm.product.url)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.i18n.label.chooseOption) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm.shouldNotifyMeBeDispalyed()
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-tertiary tile-button",
                                attrs: { type: "button", tabindex: "0" },
                                on: {
                                  click: function($event) {
                                    _vm.handleNotifyMe($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.label.notifyMe))]
                            )
                          : _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary add-to-cart-btn tile-button",
                                class: {
                                  disabled: _vm.shouldAddtocartBeDisabled()
                                },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.shouldAddtocartBeDisabled(),
                                  "aria-label": _vm.i18n.label.addToCart
                                },
                                on: {
                                  click: _vm.addToCart,
                                  keydown: function($event) {
                                    _vm.checkIfLast($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.label.addToCart))]
                            )
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.guestListName
            ? [
                _c("vx-save-cart", {
                  attrs: {
                    prodQuantity: "1",
                    analyticsObjects: _vm.analyticsObject,
                    "cart-guid": _vm.checkVariant(_vm.product),
                    i18n: _vm.i18n,
                    "is-product": "true",
                    "guest-list-name": _vm.guestListName
                  },
                  on: { "list-saved": _vm.onSelectListSuccess }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "selectListModal",
          attrs: { size: "small", heading: _vm.i18n.selectListHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              analyticsObjects: _vm.analyticsObject,
              prodQuantity: "1",
              "cart-guid": _vm.checkVariant(_vm.product),
              i18n: _vm.i18n,
              "is-product": "true"
            },
            on: { "list-saved": _vm.onSelectListSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "notifyMeModal",
          attrs: { size: "extra-small", heading: _vm.i18n.notifyMeHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c("vx-notify-me", {
            attrs: {
              slot: "component",
              title: _vm.i18n.notifyMeContent,
              productCode: _vm.product.code,
              i18n: _vm.i18n
            },
            on: {
              "notify-me-success": _vm.onNotifyMeSuccess,
              "notify-me-error": _vm.onNotifyMeError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "asmModal",
          attrs: { size: "small", heading: _vm.i18n.label.asmHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c(
            "div",
            {
              staticClass: "asm-message",
              attrs: { slot: "component" },
              slot: "component"
            },
            [_vm._v("\n      " + _vm._s(_vm.i18n.label.asmMessage) + "\n    ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-25e3a1cc", { render: render, staticRenderFns: staticRenderFns })
  }
}